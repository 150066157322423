import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/dashboard.scss"

const ScheduleBlock = ({ color, status, appointmentStatus, time, day }) => (
  <div className="tooltip-container">
    <div
      style={{
        width: "40px",
        height: "20px",
        backgroundColor: color,
        borderRadius: "4px",
        margin: "0 2px",
      }}
    ></div>
    <span className="tooltip">
      <b>
        {status === "BLOCKED" ? appointmentStatus.toUpperCase() : status.toUpperCase()} <br/>
          Date: {day} <br/>
          Slot: {time}
      </b>
    </span>
  </div>
);

const handleSlotsColor = (slotStatus, appointmentStatus) => {
  switch(slotStatus) {
    case "UNAVAILABLE":
      return "#FFAC1C";
    case "BLOCKED":
      return handleAppointmentSlotsColor(appointmentStatus);
    case "AVAILABLE":
      return "#7CFC00";
    default:
      return "red"; 
  }
}

const handleAppointmentSlotsColor = (appointmentStatus) => {
  switch (appointmentStatus) {
    case "cancelled":
      return "#FF5733";
    case "scheduled":
      return "#008000";
    case "rescheduled":
      return "#CC7722";
    case "checkIn":
      return "#5D3FD3";
    case "completed":
      return "#009E60";
    default:
      return "red";
  }

}

const DoctorSchedule = ({ title, timeSlots, appointments }) => {
  return (
    <div
      className="card doctor-schedule"
      style={{
        height: "250px",
        padding: "10px",
        borderRadius: "12px",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="card-title">{title}</h5>
        <div>
          <button className="btn btn-link p-0">
            <i className="bi bi-chevron-left"></i>
          </button>
          <button className="btn btn-link p-0">
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>
      <div>
        <table style={{ width: "100%", tableLayout: "auto", borderCollapse: "collapse" }}>
          {/* Time Slots Header */}
          <thead>
            <tr>
              {["Date", ...timeSlots].map((time, index) => (
                <th key={index} style={{ width: index === 0 ? "74px" : "53px", textAlign: "center" }}>
                  {time}
                </th>
              ))}
            </tr>
          </thead>

          {/* Doctor Schedule Rows */}
          <tbody>
            {appointments?.map((appointment, index) => {            
              return (
              <tr key={index}>
                {[{}, ...appointment?.slots].map((slot, i) => (
                  <td key={i} style={{ height: "20px", whiteSpace: "nowrap", textAlign: 'right' }}>
                    { i === 0 
                    ? new Date(appointment?.day)?.toLocaleDateString("in") 
                    : <ScheduleBlock 
                          color={handleSlotsColor(slot.status, slot.appointmentStatus)}  
                          status={slot.status} appointmentStatus={slot.appointmentStatus}
                          time={slot.time}
                          day={new Date(appointment?.day)?.toLocaleDateString("in")}
                      />
                    }
                  </td>
                ))}
              </tr>
            )})}
          </tbody>
        </table>

      </div>
    </div>
  )
}

export default DoctorSchedule
