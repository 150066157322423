import ButtonMain from "components/Common/Button"
import {
  createAisle,
  createInventoryType,
  deleteAisle,
  deleteInventoryType,
  getAisleById,
  getInventoryTypeById,
  updateAisle,
  updateInventoryType,
} from "helpers/api_helper"

import * as Yup from "yup"
import { useFormik } from "formik"

import React, { useEffect, useMemo, useReducer, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showMessage } from "components/Notification"

import {
  getAllInventoryType,
  getInventoryTypes,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import { IconSVG } from "components/Common/IconSvg"
import { BillingName, OrderId } from "pages/Parents/components/PatientsCol"
import TableContainer from "components/Common/TableContainer"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
const AddInventoryType = () => {
  //meta title
  document.title = "Pharmacy"
  // const appointmentType = useSelector(getServices)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})

  const inventoryTypes = useSelector(getAllInventoryType)
  const userDetails = useSelector(getUserDetails)
  const [selectedType, setSelectedType] = useState(null)

  const { typeId } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: details?.title || "",
      attributes: details?.attributes || "",
      description: details?.description || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Category Type"),
      attributes: Yup.string().required("Please Enter Attribute"),
    }),
    onSubmit: async values => {
      const obj = {
        ...values,
        vetId: userDetails._id,
      }

      handleSubmit(obj)
      console.log("obj", obj)
    },
  })

  const history = useHistory()
  useEffect(() => {
    async function fetchData() {
      if (!typeId) return
      dispatch(setGlobalLoading(true))
      const _detail = await getInventoryTypeById(typeId)
      setDetails(_detail.data)
      console.log("Details", _detail)

      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [typeId])

  const handleSubmit = async values => {
    console.log(values, "bbbbvvvbbbvvb")
    // return
    try {
      setIsLoading(true)
      let res
      if (!typeId) {
        res = await createInventoryType(values)
      } else {
        res = await updateInventoryType(typeId, values)
      }

      if (res.status === 1) {
        showMessage({ label: res.message, status: "success" })
        dispatch(getInventoryTypes({ vetId: userDetails?._id }))
        validation.resetForm()
        if (typeId) {
          history.push("/inventory-items-recieved")
        }
      } else {
        showMessage({
          label: res.message || "something went wrong",
          status: "error",
        })
      }
      setIsLoading(false)
    } catch (error) {
      showMessage({
        label: error.response.data.message || "something went wrong",
        status: "error",
      })
      setIsLoading(false)
    }
  }
  useEffect(() => {
    async function newLoad() {
      dispatch(setGlobalLoading(true))
      await dispatch(getInventoryTypes({ vetId: userDetails._id }))
      dispatch(setGlobalLoading(false))
    }
    newLoad()
  }, [dispatch])

  const onClickDelete = aisle => {
    setSelectedType(aisle)
    setDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Attribute",
        accessor: "attributes",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(
                    `/edit-inventoryType/${cellProps.row.original._id}`
                  )
                }}
              >
                <i id="edittooltip">
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  console.log("inventoryTypes", inventoryTypes)
  const handleDeleteOrder = async () => {
    // if (order._id) {
    const res = await deleteInventoryType(selectedType._id)
    if (res.status == 1) {
      dispatch(getInventoryTypes({ vetId: userDetails._id }))
    }
    setDeleteModal(false)
  }
  console.log("typeId", typeId)

  const handleCancel = () => {
    // validation.resetForm()
    history.push("/inventory")
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row className="mx-2 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={36}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-36">
                Add Inventory Category
              </h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4"></Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumbs
            title="Inventory"
            titleLink="/inventory"
            breadcrumbItem={"Add Inventory Category"}
          /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {/* <Row> */}

                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="title"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Category Type<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="title"
                              name="title"
                              type="text"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              placeholder="Enter Category Name"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="title"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Attributes<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <Input
                              id="attributes"
                              name="attributes"
                              type="text"
                              value={validation.values.attributes || ""}
                              onChange={validation.handleChange}
                              className="form-control"
                              placeholder="Enter Attribute"
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.attributes &&
                                validation.errors.attributes
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.attributes &&
                            validation.errors.attributes ? (
                              <FormFeedback type="invalid">
                                {validation.errors.attributes}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    <Row>
                      <Col md={7} className="pl-0">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="description"
                            className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Description<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <textarea
                              className="form-control"
                              id="description"
                              rows="8"
                              name="description"
                              style={{ resize: "none", minHeight: 200 }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                              placeholder="Notes"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}></Col>
                    </Row>
                    {inventoryTypes.length > 0 ? (
                      <Row>
                        <Col xs="12">
                          <Card>
                            <CardBody>
                              <TableContainer
                                columns={columns}
                                data={inventoryTypes}
                                isGlobalFilter={false}
                                // isAddOptions={true}
                                // handleOrderClicks={handleOrderClicks}
                                emptyListLink="/add-manufacturers"
                                emptyText="No Manufacturer found"
                                // emptyLinkText="+ Add New Items"
                                customPageSize={10}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row className="justify-content-end mt-8 ms-15">
                      <ButtonMain
                        // isLoading={isLoading}
                        type="button"
                        className="btn btn-med btn-primary me-3"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </ButtonMain>

                      <ButtonMain
                        // isLoading={isLoading}
                        type="submit"
                        className="btn btn-med btn-primary"
                      >
                        {typeId ? "Update" : "Add"}
                      </ButtonMain>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddInventoryType
