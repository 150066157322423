import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import { OrderId, BillingName, Total, Parent } from "./components/PatientsCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap"
import { deletePet } from "helpers/api_helper"
import {
  getGeneralPatientsList,
  getUserDetails,
  setGlobalLoading,
} from "../../store/slices"
import { IconSVG } from "components/Common/IconSvg"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
function GeneralPatients() {
  //meta title
  document.title = "Patients"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [showFullResults, setShowFullResults] = useState(false)
  const [selectedPatients, setSelectedGeneralPatients] = useState(null)

  const history = useHistory()

  const toggleViewModal = () => setModal1(!modal1)

  const dispatch = useDispatch()

  const generalPatients = useSelector(state => state.Patients.generalPatients)
  console.log("patients", generalPatients)
  const userDetails = useSelector(getUserDetails)
  console.log("userDetails", userDetails)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }

  //delete order
  // const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = patient => {
    setSelectedGeneralPatients(patient)
    setDeleteModal(true)
  }

  const handleDeleteOrder = async () => {
    // if (order._id) {
    const res = await deletePet({ petId: selectedPatients._id })
    if (res.status == 1) {
      await dispatch(getGeneralPatientsList(userDetails._id))
    }
    setDeleteModal(false)
  }

  //xpecto Developer 22/7/2024
  const handleOrderClicks = () => {
    history.push("/add-pet")
  }
  //xpecto Developer 22/7/2024

  useEffect(() => {
    async function fetchData() {
      dispatch(setGlobalLoading(true))
      await dispatch(getGeneralPatientsList(userDetails._id))
      dispatch(setGlobalLoading(false))
    }
    fetchData()
  }, [dispatch])

  console.log("generalPatientsList", generalPatients)
  const PatientName = cell => {
    return (
      <Link to={`/pet/${cell.row?.original?._id}`} className="font-fontFamily">
        {cell.value ? cell.value : ""}
      </Link>
    )
  }
  //xpecto Developer  22/7/2024

  const PetParent = cell => {
    // console.log("cell.row?.original?.parentId",cell.row?.original?.parentId._id)
    return (
      <Link
        to={`/parent/${cell.row?.original?.parentId?._id}`}
        className="font-fontFamily"
      >
        {cell.value ? cell.value : ""}
      </Link>
    )
  }

  //xpecto Developer  22/7/2024
  const columns = useMemo(
    () => [
      {
        Header: "Patient ID",
        accessor: "petId",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <PatientName {...cellProps} />
        },
      },
      {
        Header: "Pets Name",
        accessor: "fullName",
        filterable: true,
        Cell: cellProps => {
          return <PatientName {...cellProps} />
        },
      },
      {
        Header: "Pet Parent",
        accessor: "ParentName",
        filterable: true,
        Cell: cellProps => {
          return <PetParent {...cellProps} />
        },
      },
      {
        Header: "Pet Gender",
        accessor: "gender",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Breed",
        accessor: "petBreed",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(`/edit-patient/${cellProps.row.original._id}`)
                }}
              >
                <i id={"edittooltip" + cellProps.row.original._id}>
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip
                  placement="top"
                  target={"edittooltip" + cellProps.row.original._id}
                >
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i id={"deletetooltip" + cellProps.row.original._id}>
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip
                  placement="top"
                  target={"deletetooltip" + cellProps.row.original._id}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState([])

  useEffect(() => {
    const filtered = generalPatients.filter(
      item =>
        item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.ParentName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.petId?.includes(searchTerm.toLowerCase())
    )
    setFilteredItems(filtered)
  }, [generalPatients, searchTerm])
  console.log("FilteredItems", filteredItems)
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row className="appointment-heading-div mx-2 ">
            <Col
              className="d-flex align-items-center justify-content-start gap-2"
              xs="auto"
            >
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">Patients</h4>
            </Col>

            <Col
              className="d-flex align-items-center justify-content-between gap-4 "
              xs="auto"
            >
              <div>
                <div>
                  <FormGroup className="mb-4" row>
                    <div>
                      <Input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className="form-control"
                        placeholder="Search by Pet Parent / Pet Name / Pet ID"
                        value={searchTerm}
                        style={{
                          width: "444px",
                          border: "none",
                          borderBottom: "1px solid #B5B5B5",
                          borderRadius: "0",
                          paddingLeft: "0",
                          paddingBottom: "0",
                          backgroundColor: "transparent",
                        }}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {searchTerm && (
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "444px",
                          height: filteredItems.length > 0 ? "334px" : "60px",
                          background: "#FFFFFF",
                          zIndex: "1",
                          boxShadow: "0px 18px 30px 16px #00000008",
                          borderRadius: "12px",
                          overflowY: showFullResults ? "scroll" : "hidden",
                        }}
                      >
                        {filteredItems.length > 0 ? (
                          <>
                            {showFullResults ? (
                              <>
                                {filteredItems.map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/pet/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {filteredItems.slice(0, 3).map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/pet/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                                {filteredItems.length > 3 && (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "auto",
                                      marginLeft: "27px",
                                      marginRight: "27px",
                                      marginBottom: "30px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFullResults(true)}
                                  >
                                    <i className="fa fa-search me-1" />
                                    See All items
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              marginTop: "14px",
                              marginLeft: "24px",
                              textAlign: "center",
                            }}
                          >
                            No Patients Found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Row className="my-2">
            <div className="appointment-heading-div d-flex  align-items-center">
              <div className="d-sm-flex align-items-end">
                <img
                  src={LeftArrow}
                  width={25}
                  onClick={() => history.goBack()}
                  className="mt-2"
                  role="button"
                />
                <h4 className="mb-sm-0 font-size-18">Patients</h4>
              </div>

              <div>
              <div>
      <FormGroup className="mb-4" row>
        <div>
          <Input
            id="itemName"
            name="itemName"
            type="text"
            className="form-control"
            placeholder="Search by Pet Parent / Pet Name / Pet ID"
            value={searchTerm}
            style={{
              width: "444px",
              border: "none",
              borderBottom: "1px solid #E8E8E8",
              borderRadius: "0",
              paddingLeft: "0",
              paddingBottom: "0",
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoComplete="off"
          />
        </div>
      </FormGroup>
      {searchTerm && (
        <div style={{ position: "relative", marginTop: "10px" }}>
          <div
            style={{
              position: "absolute",
              top: 50,
              right: 10,
              width: "444px",
              height: "334px",
              background: "#FFFFFF",
              zIndex: "1",
              boxShadow: "0px 18px 30px 16px #00000008",
              borderRadius: "12px",
              overflowY: showFullResults ? "scroll" : "hidden",
            }}
          >
            {filteredItems.length > 0 ? (
              <>
                {showFullResults ? (
                  <>
                    {filteredItems.map((ele, index) => (
                      <div
                        key={index}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginTop: "30px",
                          marginLeft: "27px",
                        }}
                      >
                        <Link
                          to={`/pet/${ele._id}`}
                          style={{ color: "black" }}
                        >
                          {ele.fullName}
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {filteredItems.slice(0, 3).map((ele, index) => (
                      <div
                        key={index}
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginTop: "30px",
                          marginLeft: "27px",
                        }}
                      >
                        <Link
                          to={`/pet/${ele._id}`}
                          style={{ color: "black" }}
                        >
                          {ele.fullName}
                        </Link>
                      </div>
                    ))}
                    {filteredItems.length > 3 && (
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginTop: "auto",
                          marginLeft: "27px",
                          marginRight: "27px",
                          marginBottom: "30px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowFullResults(true)}
                      >
                        <i className="fa fa-search me-1" />
                        See All items
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginTop: "30px",
                  marginLeft: "27px",
                  textAlign: "center",
                }}
              >
                No items found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
              </div>
            </div>
          </Row> */}

          {/* xpecto Developer 22/7/2024*/}

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={generalPatients || []}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    emptyListLink="/add-pet"
                    emptyText="No Pet found"
                    emptyLinkText="+ New Patient"
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* xpecto Developer 22/7/2024*/}
        </div>
      </div>
    </React.Fragment>
  )
}

export default GeneralPatients
