import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types";
import "./appointment.scss";

import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import TableContainer from "../../components/Common/TableContainer"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//import Images

import DeleteModal from "./DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import {
  getTodaysAppointments,
  fetchAppointmentTypes,
  getUserDetails,
  getAppointmentsByDocId,
  getAppointmentsOfDoc,
  setGlobalLoading,
} from "store/slices"
import {
  getAppointmentsByWeek,
  modifyEventsData,
  reasonOptions,
} from "helpers/utils"
import {
  BillingName,
  NumberVal,
  OrderId,
  Time,
  Total,
} from "pages/Pets/components/PatientsCol"
import { deleteAppointment } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import toggleIcon1 from "../../assets/icons/calender-Icons.png"
import toggleIcon2 from "../../assets/icons/table-toggle.png"
import toggleIcon3 from "../../assets/icons/Active-Calender.png"
import toggleIcon4 from "../../assets/icons/Inactive-table.png"
import navigateForword from "../../assets/icons/navigate-forword.png"
//calender buttons css
import "../../assets/scss/custom/components/_calender.scss"
import { Parent } from "pages/Parents/components/PatientsCol"
import SearchBarComponent from "components/Common/SearchBar"
import ReactSelect from "react-select"

import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-toastify/dist/ReactToastify.css"
import CustomToolbar from "./components/CalenderCustomToolbar"
import ErrorBoundary from "./ErrorBoundary"

const Appointments = props => {
  //meta title
  document.title = "Appointments | SuPaw"

  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [currentSelected, setCurrentSelected] = useState(-1)
  const [reasonType, setReasonType] = useState(null)

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new Appointment
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      //   toggle()
    },
  })

  const appointments = useSelector(getAppointmentsOfDoc) || []
  console.log("appointments", appointments)

  const upcomingAppointments = appointments.filter(
    ele => ele.status === "scheduled"
  )

  const moifiedEvents = modifyEventsData(appointments)
  console.log(moifiedEvents)
  const modifiedEventsByDay = getAppointmentsByWeek(upcomingAppointments)
  console.log(modifiedEventsByDay)
  const userDetails = useSelector(getUserDetails)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleting, setIsdeleting] = useState(false)
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchAppointmentTypes())
  }, [dispatch])

  useEffect(() => {
    modifiedEventsByDay
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (userDetails?._id) {
        console.log(userDetails?.id, "iiidid")
        dispatch(setGlobalLoading(true))
        await dispatch(getAppointmentsByDocId(userDetails?._id))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchData()
  }, [dispatch])

  const handleDateClick = arg => {}

  /**
   * Handling click on event on calendar
   */
  const handleDeleteEvent = async () => {
    try {
      setIsdeleting(true)
      const res = await deleteAppointment(currentSelected)
      if (res.status == 1) {
        await dispatch(getAppointmentsByDocId(userDetails?._id))
      }
      setIsdeleting(false)
      setDeleteModal(false)
    } catch (error) {
      setIsdeleting(false)
      setDeleteModal(false)
    }
  }

  /**
   * On delete event
   */
  const handleEventClick = arg => {
    history.push(`/edit-appointment/${arg.event._def.publicId}`)
  }

  const onClickDelete = arg => {
    // dispatch(onDeleteEvent(event))
    setCurrentSelected(arg._id)
    setDeleteModal(true)
    // toggle()
  }

  const PatientName = cell => {
    return (
      <Link
        to={`/pet/${cell.row?.original?.petObjectId?._id}`}
        className="font-fontFamily"
      >
        {cell.value ? cell.value : ""}
      </Link>
    )
  }
  const Cost = cell => {
    if (typeof cell.value === "number") {
      return "Rs " + Number(cell.value)
    }
    return ""
  }

  const columns = useMemo(
    () => [
      {
        Header: "Pets Name",
        accessor: "petObjectId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <PatientName {...cellProps} />
        },
      },
      // {
      //   Header: "Pet Parent",
      //   accessor: "parentId.fullName",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <Parent {...cellProps} />
      //   },
      // },
      {
        Header: "Breed",
        accessor: "petObjectId.petBreed",
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Pet Parent",
        accessor: "parentId.fullName",
        filterable: true,
        Cell: cellProps => {
          return <Parent {...cellProps} />
        },
      },
      {
        Header: "Booked At",
        accessor: "time",
        filterable: true,
        Cell: cellProps => {
          return <Time {...cellProps} />
        },
      },
      {
        Header: "Appointment Type",
        accessor: "visitType",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Visits",
        accessor: "visits",
        filterable: true,
        Cell: cellProps => {
          return <NumberVal {...cellProps} />
        },
      },
      {
        Header: "Cost",
        accessor: "cost",
        filterable: true,
        Cell: cellProps => {
          return <Cost {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Reasons",
        accessor: "reasonForVisit",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          console.log("cellProps", cellProps)
          return (
            <div className="d-flex gap-3 justify-content-end">
              {cellProps.row.original.status === "scheduled" &&
              cellProps.row.original.status !== "completed" ? (
                <Link
                  to={`/edit-appointment/${cellProps.row.original._id}`}
                  className="text-info"
                >
                  <i id="edit1tooltip">
                    <IconSVG icon="edit" />
                  </i>
                  <UncontrolledTooltip placement="top" target="edit1tooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : null}
              {cellProps.row.original.status === "completed" && (
                <Link
                  to={`/prescription-details/${cellProps.row.original._id}`}
                  className="text-success"
                >
                  <i id="prec">
                    <IconSVG icon="more" />
                  </i>
                  <UncontrolledTooltip placement="top" target="prec">
                    Prescription
                  </UncontrolledTooltip>
                </Link>
              )}
              {cellProps.row.original.status !== "completed" &&
                cellProps.row.original.status !== "cancelled" && (
                  <Link
                    to={`${
                      cellProps.row.original.status === "checkIn"
                        ? "prescription"
                        : "checkin"
                    }/${cellProps.row.original._id}`}
                    className="text-success"
                  >
                    <i id={"edittooltip" + cellProps.row.original._id}>
                      <IconSVG icon="checkin" />
                    </i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"edittooltip" + cellProps.row.original._id}
                    >
                      {cellProps.row.original.status === "checkIn"
                        ? "Prescription"
                        : "Checkin"}
                    </UncontrolledTooltip>
                  </Link>
                )}

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const appointmentData = cellProps.row.original
                  onClickDelete(appointmentData)
                }}
              >
                <i id="deletetooltip">
                  <IconSVG icon="delete" />
                </i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const [toggleAppointmentPage, setToggleAppintmentPage] = useState(true)

  const displayCalender = {
    display: toggleAppointmentPage ? "" : "none",
  }
  const displayTable = {
    display: toggleAppointmentPage ? "none" : "",
  }

  function handleNav(event) {
    console.log(history.push(`/edit-appointment/${event}`))
  }

  //Filter todays appoitment data

  const currentDate = new Date()
  const todaysAppointments = appointments.filter(appointment => {
    const appointmentDate = new Date(appointment.date)
    return (
      appointmentDate.getDate() === currentDate.getDate() &&
      appointmentDate.getMonth() === currentDate.getMonth() &&
      appointmentDate.getFullYear() === currentDate.getFullYear()
    )
  })

  console.log(todaysAppointments, "ottotoo")
  const handleCustomButtonClick = () => {
    history.push("/add-appointment")
  }

  const renderCustomButton = () => (
    <div className="custom-button">
      <button
        className="custom-button__button"
        onClick={handleCustomButtonClick}
      >
        Create New Appointment
      </button>
    </div>
  )

  // const transformedEvents = modifiedEventsByDay[0].events.map(event => ({
  //   title: event.title,
  //   start: new Date(new Date(event.start).getTime() - 5.5 * 60 * 60 * 1000), // Subtract 5 hours 30 minutes
  //   end: new Date(
  //     new Date(event.start).getTime() - 5.5 * 60 * 60 * 1000 + 30 * 60 * 1000
  //   ), // Subtract 5 hours 30 minutes and add 30 minutes for the end time
  //   id: event.id,
  // }))

  const AllAppoinmentList = appointments.flatMap(appointment =>
    // appointment.doctorId.specialization.map(spec => spec.label)
    ({
      title: appointment.petObjectId.fullName,
      // start: new Date(appointment.date), // Start time
      start: new Date(
        new Date(appointment.date).getTime() - 5.5 * 60 * 60 * 1000
      ),
      end: new Date(
        new Date(appointment.date).getTime() -
          5.5 * 60 * 60 * 1000 +
          30 * 60 * 1000
      ), // Subtract 5 hours 30 minutes and add 30 minutes for the end time
      id: appointment.petObjectId._id,
      status: appointment.status,
      appointmentId: appointment?._id || ""
    })
  )

  useEffect(() => {
    if(AllAppoinmentList && AllAppoinmentList.length) {
      setEvents([...AllAppoinmentList]);
    }
  }, [AllAppoinmentList])

  console.log(AllAppoinmentList, "AllAppoinmentList")

  // const transformedEvents = modifiedEventsByDay.flatMap(day =>
  //   day.events.map(event => ({
  //     title: event.title,
  //     start: new Date(new Date(event.start).getTime() - 5.5 * 60 * 60 * 1000), // Subtract 5 hours 30 minutes
  //     end: new Date(
  //       new Date(event.start).getTime() - 5.5 * 60 * 60 * 1000 + 30 * 60 * 1000
  //     ), // Subtract 5 hours 30 minutes and add 30 minutes for the end time
  //     id: event.id,
  //   }))
  // )

  const [events, setEvents] = useState([...AllAppoinmentList])

  const localizer = momentLocalizer(moment)

  const handleSelectSlot = ({ id }) => {
    console.log(id, "iii")
    history.push(`/checkin/${id}`)
  }

  const handleEventClickFromCalender = event => {
    // const confirmDelete = window.confirm(`Delete event '${event.title}'?`)
    // if (confirmDelete) {
    //   setEvents(events.filter(e => e !== event))
    //   toast.error("Event deleted!")
    // }
    history.push(`/pet/${event.id}?appointmentId=${event.appointmentId}`)
  }

  const eventStyleGetter = event => {
    let backgroundColor = "#3174ad" // default color
    if (event.status === "completed") {
      backgroundColor = "#3E7D3E"
    } else if (event.status === "scheduled") {
      backgroundColor = "#672b88"
    } else if (event.status === "Cancelled") {
      backgroundColor = "#F5222D"
    } else if (event.status == "checkIn") {
      backgroundColor = "#E8A84C"
    }
    // if (event.status == "checkIn") {
    //   backgroundColor = "red"
    // }
    // console.log(event.status, "checkin")

    const style = {
      // backgroundColor: "#672b88", // Set the background color for the events
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    }
    return {
      style: style,
    }
  }

  console.log(modifiedEventsByDay, "mooo")

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row className="appointment-heading-div mb-4">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">Appointments</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className="d-flex align-items-center justify-content-between gap-4 mr-4"
              style={{ zIndex: 10 }}
            >
              <div
                className="appointments-toggle-page-icons-div"
                style={{ cursor: "pointer" }}
                onClick={() => setToggleAppintmentPage(!toggleAppointmentPage)}
              >
                {" "}
                <img
                  alt="toggle-page-icons1"
                  src={toggleAppointmentPage ? toggleIcon3 : toggleIcon1}
                />
                <img
                  src={toggleAppointmentPage ? toggleIcon4 : toggleIcon2}
                  alt="toggle-page-icon2"
                />
              </div>
              <SearchBarComponent reasonType={reasonType} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row style={displayCalender}>
                <Col className="col-lg-12 mb-5">
                  {/* <FullCalendar
                    className="custom-header"
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    slotDuration={"00:30:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "dayGridMonth,dayGridWeek,dayGridDay",

                      right: " customButton prev,next title",
                    }}
                    events={modifiedEventsByDay}
                    selectable={true}
                    dateClick={handleDateClick}
                    eventColor="#FAFAFA"
                    // eventClick={handleEventClick}
                    eventContent={({ event }) => (
                      <div className="calender-event-container">
                        <div className="calender-total-appointments">
                          {event.title}
                        </div>

                        <div className="calender-appointment-timings-div">
                          {event.extendedProps.times.map((time, index) => (
                            <span
                              key={index}
                              onClick={() =>
                                handleNav(event.extendedProps.events[index].id)
                              }
                              className="calender-appointment-timings"
                            >
                              {time},
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    customButtons={{
                      customButton: {
                        text: "+ Create Appointment",
                        click: handleCustomButtonClick,
                      },
                    }}
                    customButtonRender={renderCustomButton}
                  /> */}

                  <ErrorBoundary>
                    <Calendar
                      localizer={localizer}
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      selectable
                      onSelectEvent={handleEventClickFromCalender}
                      eventPropGetter={eventStyleGetter}
                      components={{
                        toolbar: CustomToolbar,
                      }}
                    />
                  </ErrorBoundary>
                </Col>
              </Row>
              <Row className="mt-20" style={displayTable}>
                <div className="d-flex justify-content-between align-items-center margin-bottom-39">
                  <div>
                    <h2 className="font-fontFamily fontSize-20">
                      Today's Appointments
                    </h2>
                  </div>
                  <div
                    className="font-fontFamily fontSize-14 text-color-primary font-hover-underline"
                    onClick={() => history.push(`/all-appointment`)}
                    role="button"
                  >
                    All Appointments <img src={navigateForword} alt="forword" />
                  </div>
                </div>

                <TableContainer
                  columns={columns}
                  // data={appointments || []}
                  data={todaysAppointments || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  emptyListLink="/add-appointment"
                  emptyText="No Appointments found"
                  emptyLinkText="+ Add Appointment"
                  customPageSize={10}
                  setReasonType={setReasonType}
                  reasonType={reasonType}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Appointments.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Appointments
