import React from "react"
import { Doughnut } from "react-chartjs-2"
import "./style/dashboard.scss"

export const DonutChart = ({ title, data, labels, colors, text }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide legend inside the chart
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const label = tooltipItem.label || ""
            const value = tooltipItem.raw || 0
            return `${label}: ${value}`
          },
        },
      },
    },
    cutout: "80%", // Adjust the inner cutout for the donut effect
  }

  return (
    <div className={`card chart-card`} style={{ borderRadius: "15px" }}>
      <div className="card-body text-center">
        <h5 className="card-title">{title}</h5>
        <Doughnut data={chartData} options={options} />
        <div className="centered-text">
          <h3>{data.reduce((a, b) => a + b, 0)}</h3> {/* Total value */}
          <p>{text}</p>
        </div>
        <div className="legend">
          {labels.map((label, index) => (
            <span key={index} className="legend-item">
              <span
                className="bullet"
                style={{ backgroundColor: colors[index] }}
              ></span>{" "}
              {label}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
