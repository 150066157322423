// import React from "react"
// import { Card, ListGroup, Button } from "react-bootstrap"

// const UpcomingBirthdays = () => {
//   const birthdays = [
//     { name: "Bella", date: "2024-09-30" },
//     { name: "Max", date: "2024-10-05" },
//     { name: "Rexy", date: "2024-10-10" },
//     // { name: "Milo", date: "2024-10-15" },
//   ]

//   return (
//     <div
//       className="mb-4 diffcard"
//       style={{
//         backgroundColor: "#f8f9fa",
//         borderRadius: "15px",
//         boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
//         padding: "20px",
//         height: "360px",
//       }}
//     >
//       <h5 style={{ fontWeight: "bold", textAlign: "center" }}>
//         Upcoming Event 🎉
//       </h5>
//       <ListGroup variant="flush">
//         {birthdays.map((birthday, index) => (
//           <ListGroup.Item
//             key={index}
//             style={{
//               padding: "15px",
//               borderBottom: "1px solid #e9ecef",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <div>
//               <h6 style={{ color: "#333", fontWeight: "600" }}>
//                 {birthday.name}
//               </h6>
//               <p className="text-muted mb-0" style={{ fontSize: "0.9rem" }}>
//                 Birthday: {new Date(birthday.date).toLocaleDateString()}
//               </p>
//             </div>
//             <Button
//               variant="primary"
//               style={{
//                 backgroundColor: "#6f42c1",
//                 borderColor: "#6f42c1",
//                 transition: "background-color 0.3s ease",
//               }}
//               onMouseEnter={e =>
//                 (e.currentTarget.style.backgroundColor = "#5a3187")
//               }
//               onMouseLeave={e =>
//                 (e.currentTarget.style.backgroundColor = "#6f42c1")
//               }
//             >
//               Celebrate!
//             </Button>
//           </ListGroup.Item>
//         ))}
//       </ListGroup>
//       <div className="text-center mt-3">
//         <Button
//           variant="outline-secondary"
//           style={{
//             transition: "transform 0.2s ease-in-out",
//             borderColor: "#6f42c1",
//             color: "#6f42c1",
//           }}
//           onMouseEnter={e => {
//             e.currentTarget.style.transform = "translateY(-3px)"
//             e.currentTarget.style.backgroundColor = "#6f42c1" // Purple background on hover
//             e.currentTarget.style.color = "#fff" // White text on hover
//           }}
//           onMouseLeave={e => {
//             e.currentTarget.style.transform = "translateY(0)"
//             e.currentTarget.style.backgroundColor = "transparent" // Reset background
//             e.currentTarget.style.color = "#6f42c1" // Reset text color
//           }}
//         >
//           See All Birthdays
//         </Button>
//       </div>
//     </div>
//   )
// }

// export default UpcomingBirthdays

// import React from "react"
// import { Button, Card, ListGroup } from "react-bootstrap"
// import { FaBirthdayCake } from "react-icons/fa"

// const UpcomingBirthdays = () => {
//   const birthdays = [
//     { name: "Shinzo", date: "05-10-2024" },
//     { name: "Max", date: "08-10-2024" },
//     { name: "Bella", date: "09-10-2024" },
//   ]

//   return (
// <Card style={{ borderRadius: "15px" }}>
//   <Card.Header className="bg-white ">
//     <h5>Upcoming Birthdays</h5>
//   </Card.Header>
//   <ListGroup variant="flush">
//     {birthdays.map((birthday, index) => (
//       <ListGroup.Item
//         key={index}
//         className="d-flex align-items-center justify-content-between py-3"
//       >
//         <div className="d-flex align-items-center">
//           <FaBirthdayCake className="me-3" size={24} color="orange" />
//           <div>
//             <h6 className="mb-1">{birthday.name}</h6>
//             <small className="text-muted">{birthday.date}</small>
//           </div>
//         </div>
//         <Button variant="outline-primary" className="send-wishes-btn">
//           🎉 Send Wishes
//         </Button>
//       </ListGroup.Item>
//     ))}
//   </ListGroup>
// </Card>
//   )
// }

// export default UpcomingBirthdays

import React from "react"
import "../../assets/scss/custom.css"
import cakeIcon from "../../assets/images/BirthDayIcon.png"
import { FaBirthdayCake } from "react-icons/fa"

const UpcomingBirthdays = ({ birthdays }) => {
  // const birthdays = [
  //   { name: "Shinzo", date: "05-10-2024" },
  //   { name: "Max", date: "08-10-2024" },
  //   { name: "Bella", date: "09-10-2024" },
  //   // { name: "Bella", date: "09-10-2024" },
  // ]

  return (
    <div className="upcoming-birthdays-card birthday-card">
      <div className="upcoming-birthdays-header">
        <h5>Upcoming Birthdays</h5>
      </div>
      <div className="upcoming-birthdays-list">
        {birthdays && birthdays.length ? birthdays.map((birthday, index) => {
          let birthdayDate = new Date(birthday.dateOfBirth).setFullYear(new Date().getFullYear());
          
          return (
          <div key={index} className="birthday-item">
            <div className="birthday-info" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              {/* <image src={cakeIcon} height={50} width={20} /> */}
              <FaBirthdayCake className="birthday-icon" size={24} />

              <div style={{ width:"100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h6 className="mb-1">{birthday.fullName}</h6>
                <small className="text-muted">{new Date(birthdayDate).toLocaleDateString("in")}</small>
              </div>
            </div>
            {/* <button className="send-wishes-btn">🎉 Send Wishes</button> */}
          </div>
        )}) : (
        <div className="birthday-item" style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div>
            <h6>No birthday data found.</h6>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default UpcomingBirthdays
