import React from "react"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa" // Importing arrow icons

const CustomToolbar = toolbar => {
  const goToBack = () => {
    toolbar.onNavigate("PREV")
  }

  const goToNext = () => {
    toolbar.onNavigate("NEXT")
  }

  const goToToday = () => {
    toolbar.onNavigate("TODAY")
  }

  const goToDateView = view => {
    toolbar.onView(view)
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button className="toolbar-button" onClick={goToBack}>
          <FaArrowLeft />
        </button>{" "}
        {/* Previous arrow */}
        <button
          className="toolbar-button "
          style={{
            paddingTop: "6.5px",
            paddingBottom: "6.5px",
            marginRight: "3.5px",
          }}
          onClick={goToToday}
        >
          Today
        </button>
        <button className="toolbar-button" onClick={goToNext}>
          <FaArrowRight />
        </button>{" "}
        {/* Next arrow */}
      </span>
      <span className="rbc-toolbar-label">{toolbar.label}</span>
      <span className="rbc-btn-group">
        <button
          className="toolbar-button"
          onClick={() => goToDateView("month")}
        >
          Month
        </button>
        <button className="toolbar-button" onClick={() => goToDateView("week")}>
          Week
        </button>
        <button className="toolbar-button" onClick={() => goToDateView("day")}>
          Day
        </button>
        <button
          className="toolbar-button"
          onClick={() => goToDateView("agenda")}
        >
          Agenda
        </button>
      </span>
    </div>
  )
}

export default CustomToolbar
