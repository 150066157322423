import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { Col, Container, Row, UncontrolledTooltip } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Line } from "react-chartjs-2"
import { Bar } from "react-chartjs-2"
import Chart from "chart.js/auto"
import DeleteModal from "../Appointments/DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"
import "../../assets/scss/custom.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  getTodaysAppointments,
  getAppointmentsOfDoc,
  getAppointmentsByDocId,
  getUserDetails,
  getWeeklyRevenue,
  getWeeklyPatientCount,
  getAllWeeklyRevenue,
  getAllWeeklyPatientCount,
  getyearlyRevenueCount,
  getAllYearlyRevenue,
  setGlobalLoading,
} from "store/slices"
import { modifyEventsData } from "helpers/utils"
import { BillingName, Time, Total } from "pages/Pets/components/PatientsCol"
import { deleteAppointment } from "helpers/api_helper"
import { IconSVG } from "components/Common/IconSvg"
import "../../assets/scss/custom/pages/_dashboard.scss"
import navigateForword from "../../assets/icons/navigate-forword.png"
import vetenary from "../../assets/images/veterinary.png"
import calender from "../../assets/images/calendar.png"
import brokenLeg from "../../assets/images/broken-leg.png"
import dog from "../../assets/images/dog.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Card, Button, Image, Badge } from "react-bootstrap"
import { Pie } from "react-chartjs-2"

const Appointments = props => {
  //meta title
  document.title = "Dashboard | SuPaw"
  const history = useHistory()
  const dispatch = useDispatch()

  const [currentSelected, setCurrentSelected] = useState(-1)

  // events validation

  const appointments = useSelector(getAppointmentsOfDoc) || []
  const userDetails = useSelector(getUserDetails)
  const weeklyRevenue = useSelector(getAllWeeklyRevenue)
  const weeklyPatientCount = useSelector(getAllWeeklyPatientCount)
  const yearlyRevenuArray = useSelector(getAllYearlyRevenue)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleting, setIsdeleting] = useState(false)
  const weeklyRevenuePercent = weeklyRevenue?.percentageChange
  const weeklyPatientPercent = weeklyPatientCount?.percentage
  console.log("WeeklypatientPercent:", weeklyPatientPercent)
  useEffect(() => {
    async function fetchData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getAppointmentsByDocId(userDetails?._id))
        dispatch(getWeeklyRevenue({ vetId: userDetails._id }))
        dispatch(getWeeklyPatientCount({ vetId: userDetails._id }))
        dispatch(getyearlyRevenueCount({ vetId: userDetails._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchData()
  }, [dispatch])
  /**
   * Handling click on event on calendar
   */
  console.log(weeklyRevenue, "weeklyreve")
  const handleDeleteEvent = async () => {
    try {
      setIsdeleting(true)
      const res = await deleteAppointment(currentSelected)

      if (res.status == 1) {
        // dispatch(getTodaysAppointments())
        dispatch(getAppointmentsByDocId(userDetails?._id))
      }
      setIsdeleting(false)
      setDeleteModal(false)
    } catch (error) {
      setIsdeleting(false)
      setDeleteModal(false)
    }
  }

  // console.log(getTodaysAppointments, "appointments00")

  const handleDateClick = arg => {}
  /**
   * On delete event
   */
  const handleEventClick = arg => {}

  /**
   * On delete event
   */

  const onClickDelete = arg => {
    setCurrentSelected(arg._id)
    setDeleteModal(true)
  }
  const Parent = cell => {
    return (
      <Link to={`/parent/${cell.value}`}>{cell.value ? cell.value : ""}</Link>
    )
  }
  const DateModified = cell => {
    if (cell.value) {
      const datePart = cell.value.split("T")[0]
      return datePart
    }
    return ""
  }

  const columns = [
    {
      Header: "Pets Name",
      accessor: "petObjectId.fullName",
      filterable: true,
      Cell: cellProps => {
        return <BillingName {...cellProps} />
      },
    },
    {
      Header: "Pet Parent",
      accessor: "parentId.fullName",
      filterable: true,
      Cell: cellProps => {
        return <Parent {...cellProps} />
      },
    },
    {
      Header: "Breed",
      accessor: "petObjectId.petBreed",
      filterable: true,
      Cell: cellProps => {
        return <Total {...cellProps} />
      },
    },
    {
      Header: "Date",
      accessor: "date",
      filterable: true,
      Cell: cellProps => {
        return <DateModified {...cellProps} />
      },
    },
    {
      Header: "Start time",
      accessor: "time",
      filterable: true,
      Cell: cellProps => {
        return <Time {...cellProps} />
      },
    },
    {
      Header: "Type",
      accessor: "visitType",
      filterable: true,
      Cell: cellProps => {
        return <BillingName {...cellProps} />
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      Cell: cellProps => {
        return <BillingName {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3 justify-content-end">
            {cellProps.row.original.status === "scheduled" &&
            cellProps.row.original.status !== "completed" ? (
              <Link
                to={`/edit-appointment/${cellProps.row.original._id}`}
                className="text-info"
              >
                <i id="edit1tooltip">
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip placement="top" target="edit1tooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : null}
            {cellProps.row.original.status === "completed" && (
              <Link
                to={`/prescription-details/${cellProps.row.original._id}`}
                className="text-success"
              >
                <i id="prec">
                  <IconSVG icon="more" />
                </i>
                <UncontrolledTooltip placement="top" target="prec">
                  Prescription
                </UncontrolledTooltip>
              </Link>
            )}
            {cellProps.row.original.status !== "completed" &&
              cellProps.row.original.status !== "cancelled" && (
                <Link
                  to={`${
                    cellProps.row.original.status === "checkIn"
                      ? "prescription"
                      : "checkin"
                  }/${cellProps.row.original._id}`}
                  className="text-success"
                >
                  <i id="edittooltip">
                    <IconSVG icon="checkin" />
                  </i>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {cellProps.row.original.status === "checkIn"
                      ? "Prescription"
                      : "Checkin"}
                  </UncontrolledTooltip>
                </Link>
              )}

            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const appointmentData = cellProps.row.original
                onClickDelete(appointmentData)
              }}
            >
              <i id="deletetooltip">
                <IconSVG icon="delete" />
              </i>
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        )
      },
    },
  ]

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  function getFormattedDateWithSuffix(day) {
    let suffix
    if (day >= 11 && day <= 13) {
      suffix = "th"
    } else {
      const lastDigit = day % 10
      switch (lastDigit) {
        case 1:
          suffix = "st"
          break
        case 2:
          suffix = "nd"
          break
        case 3:
          suffix = "rd"
          break
        default:
          suffix = "th"
          break
      }
    }

    return `${day}${suffix}`
  }

  const currentDate = new Date()
  const todayDay = currentDate.getDate()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  const prices = yearlyRevenuArray.map(item => item.price)
  console.log("todaysDate", todayDay)
  console.log("currentMonth", monthNames[currentMonth])
  console.log("currentYear", currentYear)
  console.log("CurrentDate", currentDate)
  console.log("Weekly Revenue", weeklyRevenue)
  console.log("Weekly patient Count", weeklyPatientCount)
  console.log("yearlyRevenue", yearlyRevenuArray)
  console.log("yearlyIncome", prices)
  console.log("appointment:", appointments)
  const upcomingAppointments = appointments.filter(
    ele => ele.status === "scheduled"
  )
  const boxStyle = {
    boxShadow: "0px 8px 32px 0px #3326AE14",
    height: "112px",
    width: "442px",
  }
  const boxStyle2 = {
    boxShadow: "0px 8px 32px 0px #3326AE14",
    marginRight: "calc(var(--bs-gutter-x)* 0.1)",
    marginLeft: "calc(var(--bs-gutter-x)* 0.1)",
  }
  const data = {
    labels: ["Mon", "Tue", "Wed", "Thus", "Fri", "Sat"],
    datasets: [
      {
        data: [1000, 1500, 1800, 1400, 1900, 1200, 1600],
        backgroundColor: "#652B88",
        borderColor: "#652B88",
        pointBorderColor: "#652B88",
        fill: false,
        tension: 0.4,
      },
    ],
  }

  const options = {
    plugins: {
      legend: false,
      title: {
        display: false, // this hides the legend
      },
      fullSize: true,
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  }

  const data2 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Monthly Sales",
        data: prices,
        backgroundColor: context => {
          const chart = context.chart
          const { ctx } = chart
          const gradient = ctx.createLinearGradient(0, 0, 0, 400)
          gradient.addColorStop(0, "#66AE45")
          gradient.addColorStop(1, "#4CAF50")
          return gradient
        },
        borderColor: "#66AE45",
        borderWidth: 1,
        barThickness: 8,
        borderRadius: 10,
        categoryPercentage: 1.0,
        barPercentage: 0.8,
        hoverBackgroundColor: "#4CAF50",
        hoverBorderColor: "#388E3C",
        hoverBorderWidth: 2,
        fill: true,
      },
    ],
    options: {
      scales: {
        x: {
          ticks: {
            font: {
              size: 14, // Increase this value for larger font size
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 14, // Increase this value for larger font size
            },
          },
        },
      },
    },
  }

  const monthNamesForGraph = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  }

  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        labels: {
          color: "#333", // Legend label color
          font: {
            size: 14, // Increase legend font size if needed
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || ""
            const value = tooltipItem.raw
            return `${label}: ₹${value}` // Format tooltips with currency
          },
          title: function (tooltipItems) {
            const monthKey = tooltipItems[0].label // Get the label key
            return monthNamesForGraph[monthKey] || monthKey // Convert to full month name
          },
        },
        backgroundColor: "#fff", // Tooltip background color
        titleColor: "#333", // Tooltip title color
        bodyColor: "#666", // Tooltip body color
        borderColor: "#ddd", // Tooltip border color
        borderWidth: 1, // Tooltip border width
        padding: 10, // Tooltip padding
        cornerRadius: 4, // Tooltip corner radius
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
        ticks: {
          color: "#6c757d", // Axis label color
          font: {
            size: 14, // Font size for x-axis labels
          },
        },
      },
      y: {
        grid: {
          borderColor: "#e0e0e0", // Grid line color
          borderWidth: 1, // Grid line width
          drawBorder: false, // Hide y-axis border
        },
        ticks: {
          color: "#6c757d", // Axis label color
          callback: function (value) {
            return "₹" + value // Format y-axis labels with currency
          },
          font: {
            size: 14, // Font size for y-axis labels
          },
        },
      },
    },
  }

  // const options2 = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  //   plugins: {
  //     legend: {
  //       labels: {
  //         color: "#333", // Legend label color
  //       },
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (tooltipItem) {
  //           const label = tooltipItem.dataset.label || ""
  //           const value = tooltipItem.raw
  //           return `${label}: ₹${value}` // Format tooltips with currency
  //         },
  //         title: function (tooltipItems) {
  //           const monthKey = tooltipItems[0].label // Get the label key
  //           return monthNamesForGraph[monthKey] || monthKey // Convert to full month name
  //         },
  //       },
  //       backgroundColor: "#fff", // Tooltip background color
  //       titleColor: "#333", // Tooltip title color
  //       bodyColor: "#666", // Tooltip body color
  //       borderColor: "#ddd", // Tooltip border color
  //       borderWidth: 1, // Tooltip border width
  //       padding: 10, // Tooltip padding
  //       cornerRadius: 4, // Tooltip corner radius
  //     },
  //   },
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false, // Hide x-axis grid lines
  //       },
  //       ticks: {
  //         color: "#6c757d", // Axis label color
  //       },
  //     },
  //     y: {
  //       grid: {
  //         borderColor: "#e0e0e0", // Grid line color
  //         borderWidth: 1, // Grid line width
  //         drawBorder: false, // Hide y-axis border
  //       },
  //       ticks: {
  //         color: "#6c757d", // Axis label color
  //         callback: function (value) {
  //           return "₹" + value // Format y-axis labels with currency
  //         },
  //       },
  //     },
  //   },
  // }

  // Adding cursor pointer effect
  const chartOptions = {
    ...options2,
    onHover: function (event, chartElement) {
      event.native.target.style.cursor = chartElement[0] ? "pointer" : "default"
    },
  }

  const orangeStyle = {
    color: "#E75C25",
  }
  const purpleStyle = {
    color: "#652B88",
  }

  const stockCardStyle = {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
  }

  const stockCardInnerStyle = {
    backgroundColor: "#f8f9fa",
    borderRadius: "12px",
    padding: "16px",
    boxShadow: "inset 0 4px 8px rgba(0, 0, 0, 0.05)",
  }

  const monthlyRevenueCardStyle = {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
  }

  const pieData = {
    labels: ["New Patients", "Old Patients", "Total Patients"],
    datasets: [
      {
        data: [300, 150, 1000], // Example data
        backgroundColor: ["#42A5F5", "#FFCA28", "#66BB6A"],
        hoverBackgroundColor: ["#64B5F6", "#FFD54F", "#81C784"],
      },
    ],
  }
  const pieOptions = {
    maintainAspectRatio: false, // Prevents chart from resizing based on aspect ratio
    plugins: {
      legend: {
        display: false, // We'll handle the labels manually below the chart
      },
    },
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        isDeleting={isDeleting}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid={true}>
          <Row
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: "16px" }}
          >
            <div className="w-auto">
              <h4 className="font-fontFamily fontSize-28">
                Business Dashboard
              </h4>
            </div>
            <div className="w-auto ">
              <h4 className="font-fontFamily fontSize-14">
                As of {getFormattedDateWithSuffix(todayDay)}{" "}
                {monthNames[currentMonth]} {currentYear}
              </h4>
            </div>
          </Row>
          {/* Render Breadcrumb */}

          {/* <Row style={{ marginBottom: "24px" }}>
            <Col xs="12" md="4" className="d-flex mb-3 mb-md-0">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={
                      weeklyRevenue?.percentageChange >= 0
                        ? purpleStyle
                        : orangeStyle
                    }
                    className="font-fontFamily fontSize-16"
                  >
                    Weekly Revenue
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    ₹ {weeklyRevenue.totalCurrentWeekRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>

                <div className="py-2 px-2 ">
                  <h4
                    style={purpleStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyRevenuePercent ? weeklyRevenuePercent : "0%"}
                  </h4>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4" className="d-flex">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-16"
                  >
                    Weekly Patients
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    {weeklyPatientCount.currentRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>
                <div className="py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyPatientPercent ? weeklyPatientPercent : "0%"}
                  </h4>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4" className="d-flex">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-16"
                  >
                    Daily Revenue
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    {weeklyPatientCount.currentRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>
                <div className="py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyPatientPercent ? weeklyPatientPercent : "0%"}
                  </h4>
                </div>
              </div>
            </Col>
          </Row> */}

          {/* <Row style={{ marginBottom: "24px" }}>
            <Col xs="12" md="6" className="d-flex mb-3 mb-md-0">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={
                      weeklyRevenue?.percentageChange >= 0
                        ? purpleStyle
                        : orangeStyle
                    }
                    className="font-fontFamily fontSize-16"
                  >
                    Weekly Revenue
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    ₹ {weeklyRevenue.totalCurrentWeekRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>

                <div className="py-2 px-2 ">
                  <h4
                    style={purpleStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyRevenuePercent ? weeklyRevenuePercent : "0%"}
                  </h4>
                </div>
              </div>
            </Col>
            <Col xs="12" md="6" className="d-flex">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-16"
                  >
                    Weekly Patients
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    {weeklyPatientCount.currentRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>
                <div className="py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyPatientPercent ? weeklyPatientPercent : "0%"}
                  </h4>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4" className="d-flex">
              <div className="w-100 d-flex p-2 box-radius-12" style={boxStyle}>
                <div className="w-50 d-flex flex-column align-items-start justify-content-between py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-16"
                  >
                    Daily Revenue
                  </h4>
                  <h4 className="font-fontFamily fontSize-24">
                    {weeklyPatientCount.currentRevenue}
                  </h4>
                </div>
                <div
                  className="flex-grow-1 ml-md-4 py-2 px-2"
                  style={{ width: "320px" }}
                >
                  <Line data={data} options={options}></Line>
                </div>
                <div className="py-2 px-2">
                  <h4
                    style={orangeStyle}
                    className="font-fontFamily fontSize-24"
                  >
                    {weeklyPatientPercent ? weeklyPatientPercent : "0%"}
                  </h4>
                </div>
              </div>
            </Col>
          </Row> */}

          <Row style={{ marginBottom: "24px" }}>
            <Col xs="12" md="4" className="d-flex mb-3 mb-md-0">
              <div
                className="w-100 d-flex p-3 box-radius-12"
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={e =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseLeave={e =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      // backgroundColor: "#edf2fa",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={vetenary}
                      alt="icon"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                  <div>
                    <h4
                      className="font-fontFamily fontSize-16"
                      style={{ color: "#646b80" }}
                    >
                      Total Patient
                    </h4>
                    <h4
                      className="font-fontFamily fontSize-24"
                      style={{ color: "#222d4b" }}
                    >
                      2000+
                    </h4>
                    <h5
                      className="font-fontFamily fontSize-14"
                      style={{ color: "#646b80" }}
                    >
                      Till Today
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4" className="d-flex mb-3 mb-md-0">
              <div
                className="w-100 d-flex p-3 box-radius-12"
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={e =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseLeave={e =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      // backgroundColor: "#edf2fa",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={brokenLeg}
                      alt="icon"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                  <div>
                    <h4
                      className="font-fontFamily fontSize-16"
                      style={{ color: "#646b80" }}
                    >
                      Today Patient
                    </h4>
                    <h4
                      className="font-fontFamily fontSize-24"
                      style={{ color: "#222d4b" }}
                    >
                      5
                    </h4>
                    <h5
                      className="font-fontFamily fontSize-14"
                      style={{ color: "#646b80" }}
                    >
                      13/09/2024
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs="12" md="4" className="d-flex">
              <div
                className="w-100 d-flex p-3 box-radius-12"
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={e =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseLeave={e =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      // backgroundColor: "#edf2fa",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={calender}
                      alt="icon"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                  <div>
                    <h4
                      className="font-fontFamily fontSize-16"
                      style={{ color: "#646b80" }}
                    >
                      Today Appointment
                    </h4>
                    <h4
                      className="font-fontFamily fontSize-24"
                      style={{ color: "#222d4b" }}
                    >
                      20
                    </h4>
                    <h5
                      className="font-fontFamily fontSize-14"
                      style={{ color: "#646b80" }}
                    >
                      {/* {Date.now()} */}
                      13/09/2024
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: "24px" }} className="gap-3 gap-md-0">
            {/* Patients Summary (Pie Chart + Details aligned horizontally) */}
            <Col md={4}>
              <Card
                className="mb-4 p-3 shadow-sm h-100 box-radius-12 stock-card"
                style={stockCardStyle}
              >
                <h5
                  className="mb-3 font-fontFamily"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Patients Summary September 2024
                </h5>
                <div className="d-flex flex-column gap-5 justify-content-between align-items-center">
                  <div
                    // className="pie-chart-container"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Pie data={pieData} options={pieOptions} />
                  </div>
                  <div className="ms-3">
                    <h5>
                      <strong>New Patients:</strong> 300
                    </h5>
                    <h5>
                      <strong>Old Patients:</strong> 150
                    </h5>
                    <h5>
                      <strong>Total Patients:</strong> 1000
                    </h5>
                  </div>
                </div>
                {/* <div className="mt-3 text-center">
                  <div className="d-flex flex-column">
                    <Badge bg="primary">New Patients</Badge>
                    <Badge bg="warning">Old Patients</Badge>
                    <Badge bg="success">Total Patients</Badge>
                  </div>
                </div> */}
              </Card>
            </Col>

            {/* Today's Appointments */}
            <Col md={4}>
              <Card
                className="mb-4 p-3 shadow-sm h-100 box-radius-12 stock-card"
                style={stockCardStyle}
              >
                <h5
                  className="mb-3 font-fontFamily"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Today Appointments
                </h5>

                <div className="d-flex justify-content-between align-content-center align-items-center w-100  mb-3">
                  <Image src={dog} height={50} width={50} roundedCircle />
                  <div className="d-flex justify-content-between w-100 align-content-center ms-3">
                    <div className="d-flex ">
                      <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                        Tom
                      </h6>
                      <p className=" ms-2 mb-0 text-muted"> (Surgery)</p>
                    </div>
                    <span
                      className="badge bg-success px-2 py-1 align-content-center badge_hidden"
                      style={{ fontSize: "12px" }}
                    >
                      On Going
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-content-center align-items-center w-100  mb-3">
                  <Image src={dog} height={50} width={50} roundedCircle />
                  <div className="d-flex justify-content-between w-100 align-content-center ms-3">
                    <div className="d-flex ">
                      <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                        Rocky
                      </h6>
                      <p className=" ms-2 mb-0 text-muted"> (Consultant)</p>
                    </div>
                    <span
                      className="badge bg-info px-2 py-1 align-content-center badge_hidden"
                      style={{ fontSize: "12px" }}
                    >
                      04:30 PM
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-content-center align-items-center w-100  mb-3">
                  <Image src={dog} height={50} width={50} roundedCircle />
                  <div className="d-flex justify-content-between w-100 align-content-center ms-3">
                    <div className="d-flex ">
                      <h6 style={{ fontWeight: "600", fontSize: "14px" }}>
                        Bluno
                      </h6>
                      <p className=" ms-2 mb-0 text-muted"> (Fever)</p>
                    </div>
                    <span
                      className="badge bg-info px-2 py-1 align-content-center badge_hidden"
                      style={{ fontSize: "12px" }}
                    >
                      05:30 PM
                    </span>
                  </div>
                </div>

                <Button variant="link" className="p-0">
                  See All
                </Button>
              </Card>
            </Col>

            {/* Next Patient Details */}
            <Col md={4}>
              <Card
                className="mb-4 p-3 shadow-sm h-100 box-radius-12 stock-card"
                style={stockCardStyle}
              >
                <h5
                  className="mb-3 font-fontFamily"
                  style={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Next Patient Details
                </h5>

                <div className="d-flex align-items-center mb-3">
                  <Image src={dog} height={50} width={50} roundedCircle />
                  <div className="ms-3">
                    <h6 style={{ fontWeight: "600", fontSize: "16px" }}>
                      Rocky
                    </h6>
                    <p className="mb-0 text-muted">Surgery</p>
                  </div>
                </div>

                <p>
                  <strong style={{ fontWeight: "600" }}>Patient ID:</strong>{" "}
                  0220092020005
                </p>
                <p>
                  <strong style={{ fontWeight: "600" }}>D.O.B:</strong> 15
                  January 2024
                </p>
                <p>
                  <strong style={{ fontWeight: "600" }}>Sex:</strong> Male
                </p>
                <p>
                  <strong style={{ fontWeight: "600" }}>Weight:</strong> 20 Kg
                </p>
                <p>
                  <strong style={{ fontWeight: "600" }}>Height:</strong> 35 cm
                </p>
                <p>
                  <strong style={{ fontWeight: "600" }}>
                    Last Appointment:
                  </strong>{" "}
                  15 August 2024
                </p>
                <p>
                  <strong style={{ fontWeight: "600" }}>Patient History</strong>
                </p>

                <div className="d-flex justify-content-around ">
                  <Badge
                    bg="warning"
                    style={{ fontSize: "13px", padding: "5px" }}
                  >
                    Rabies
                  </Badge>
                  <Badge
                    bg="primary"
                    style={{ fontSize: "13px", padding: "5px" }}
                  >
                    Brucellosis
                  </Badge>
                  <Badge
                    bg="danger"
                    style={{ fontSize: "13px", padding: "5px" }}
                  >
                    Fever
                  </Badge>
                </div>

                {/* <div className="mt-1"> */}
                <Button variant="primary" size="sm" className="me-2 mt-5 p-2 ">
                  <i className="bi bi-phone"></i> Call
                </Button>
                {/* </div> */}
                {/* <Button variant="outline-secondary" size="sm">
                    <i className="bi bi-chat"></i> Chat
                  </Button>
                </div> */}
              </Card>
            </Col>
          </Row>

          {/* <Row style={boxStyle2} className="mb-5 box-radius-16 p-4">
            <Col xs="12" md="6" className="mb-4">
              <h4 className="font-fontFamily fontSize-20 text-color-primary">
                Stock Availability
              </h4>
              <div
                className="w-100 p-3 box-radius-12"
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
              >
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h5 className="font-fontFamily fontSize-16 text-color-primary">
                      Total Asset
                    </h5>
                    <h4 className="font-fontFamily fontSize-24">$53,000</h4>
                  </div>
                  <div>
                    <h5 className="font-fontFamily fontSize-16 text-color-primary">
                      Total Product
                    </h5>
                    <h4 className="font-fontFamily fontSize-24">442</h4>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <div style={{ width: "100%" }}>
                    <div className="d-flex align-items-center mb-1">
                      <div
                        style={{
                          backgroundColor: "#28c76f",
                          width: "60%",
                          height: "10px",
                          borderRadius: "5px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#ff9f43",
                          width: "25%",
                          height: "10px",
                          borderRadius: "5px",
                          marginLeft: "5px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#ea5455",
                          width: "15%",
                          height: "10px",
                          borderRadius: "5px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <span className="font-fontFamily fontSize-14 text-color-secondary">
                        Available
                      </span>
                      <span className="font-fontFamily fontSize-14 text-color-secondary">
                        Low Stock
                      </span>
                      <span className="font-fontFamily fontSize-14 text-color-secondary">
                        Out of stock
                      </span>
                    </div>
                  </div>
                </div>

                <h5 className="font-fontFamily fontSize-16 text-color-primary mb-2">
                  Low Stock
                </h5>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span className="font-fontFamily fontSize-16 text-color-secondary">
                    Dental Brush
                  </span>
                  <span className="font-fontFamily fontSize-16 text-color-secondary">
                    Qty: 3
                  </span>
                  <a
                    href="#"
                    className="font-fontFamily fontSize-16 text-color-primary"
                  >
                    Order
                  </a>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="font-fontFamily fontSize-16 text-color-secondary">
                    Charmflex Regular
                  </span>
                  <span className="font-fontFamily fontSize-16 text-color-secondary">
                    Qty: 2
                  </span>
                  <a
                    href="#"
                    className="font-fontFamily fontSize-16 text-color-primary"
                  >
                    Order
                  </a>
                </div>
                <div className="text-right mt-3">
                  <a
                    href="#"
                    className="font-fontFamily fontSize-14 text-color-primary"
                  >
                    View all
                  </a>
                </div>
              </div>
            </Col>

            <Col xs="12" md="6">
              <h4 className="font-fontFamily fontSize-20 text-color-primary">
                Monthly Revenue
              </h4>
              <div className="w-100">
                <Bar
                  data={data2}
                  options={{ ...chartOptions, maintainAspectRatio: false }}
                  style={{ height: "280px" }} // Adjusted height
                />
              </div>
            </Col>
          </Row> */}

          <Row className="mb-5">
            {/* Stock Availability Card */}
            <Col xs="12" md="6" className="mb-4">
              <div
                className="stock-card p-4 box-radius-12"
                style={stockCardStyle}
              >
                <h4 className="font-fontFamily fontSize-20 text-color-primary mb-4">
                  Stock Availability
                </h4>
                <div
                  className="w-100 p-3 stock-card-inner"
                  style={stockCardInnerStyle}
                >
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <h5 className="font-fontFamily fontSize-16 text-color-primary">
                        Total Asset
                      </h5>
                      <h4 className="font-fontFamily fontSize-24">$10,000</h4>
                    </div>
                    <div>
                      <h5 className="font-fontFamily fontSize-16 text-color-primary">
                        Total Product
                      </h5>
                      <h4 className="font-fontFamily fontSize-24">200</h4>
                    </div>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <div style={{ width: "100%" }}>
                      <div className="d-flex align-items-center mb-1">
                        <div
                          style={{
                            backgroundColor: "#28c76f",
                            width: "33.3%",
                            height: "10px",
                            borderRadius: "5px",
                          }}
                        ></div>
                        <div
                          style={{
                            backgroundColor: "#ff9f43",
                            width: "33.3%",
                            height: "10px",
                            borderRadius: "5px",
                            marginLeft: "5px",
                          }}
                        ></div>
                        <div
                          style={{
                            backgroundColor: "#ea5455",
                            width: "33.3%",
                            height: "10px",
                            borderRadius: "5px",
                            marginLeft: "5px",
                          }}
                        ></div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <span className="font-fontFamily fontSize-14 text-color-secondary">
                          Available
                        </span>
                        <span className="font-fontFamily fontSize-14 text-color-secondary">
                          Low Stock
                        </span>
                        <span className="font-fontFamily fontSize-14 text-color-secondary">
                          Out of stock
                        </span>
                      </div>
                    </div>
                  </div>

                  <h5 className="font-fontFamily fontSize-16 text-color-primary mb-2">
                    Low Stock
                  </h5>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="truncate-text">Dental Brush</span>

                    <span className="font-fontFamily fontSize-16 text-color-secondary">
                      Qty: 3
                    </span>
                    <a
                      href="#"
                      className="font-fontFamily fontSize-16 text-color-primary"
                    >
                      Order
                    </a>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="truncate-text"> Charmflex Regular</span>
                    {/* <span className="font-fontFamily fontSize-16 text-color-secondary">
                      Charmflex Regular
                    </span> */}
                    <span className="font-fontFamily fontSize-16 text-color-secondary">
                      Qty: 2
                    </span>
                    <a
                      href="#"
                      className="font-fontFamily fontSize-16 text-color-primary"
                    >
                      Order
                    </a>
                  </div>
                  <div className="text-right mt-3">
                    <a
                      href="#"
                      className="font-fontFamily fontSize-14 text-color-primary"
                    >
                      View all
                    </a>
                  </div>
                </div>
              </div>
            </Col>

            {/* Monthly Revenue Card */}
            <Col xs="12" md="6">
              <div
                className="monthly-revenue-card p-4 box-radius-12"
                style={monthlyRevenueCardStyle}
              >
                <h4 className="font-fontFamily fontSize-20 text-color-primary mb-4">
                  Monthly Revenue
                </h4>
                <div className="w-100">
                  <Bar
                    data={data2}
                    options={{ ...chartOptions, maintainAspectRatio: false }}
                    style={{ height: "280px" }} // Adjusted height
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="font-fontFamily fontSize-20">
                Upcoming Appointments
              </h2>
            </div>
            <div
              className="font-fontFamily fontSize-14 text-color-primary font-hover-underline"
              onClick={() => history.push(`/all-appointment`)}
              role="button"
            >
              All Appointments <img src={navigateForword} alt="forword" />
            </div>
          </div>
          <Row>
            <Col className="col-12">
              <Row className="mt-20">
                <TableContainer
                  columns={columns}
                  data={upcomingAppointments || []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  emptyListLink="/add-appointment"
                  emptyText="No Appointments found"
                  emptyLinkText="+ Add Appointment"
                  customPageSize={10}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Appointments.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Appointments
