import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"

// Reactstrap
import { Button, Modal } from "reactstrap"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import images

import logo from "../../assets/images/logo-1.png"
import logoLightSvg from "../../assets/images/logo-1.png"

import { getDetails, getUserDetails } from "store/slices"

//Dispatch
import { useDispatch } from "react-redux"

//Actions
import { changeSidebarType } from "store/actions"
import { IconSVG } from "components/Common/IconSvg"
import { updateVet } from "helpers/api_helper"
import InfoIcon from "../../assets/icons/help-circle.svg"
import InfoMessage from "components/Common/InfoMessage"

const Header = () => {
  const path = useLocation()
  const useDetails = useSelector(getUserDetails)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [step, setStep] = useState(useDetails?.instructions)
  //toggle value from redux store
  const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)
  // console.log(leftSideBarType)

  const dispatch = useDispatch()

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
      if (leftSideBarType === "default") {
        dispatch(changeSidebarType("condensed"))
      } else {
        dispatch(changeSidebarType("default"))
      }
    }
  }
  useEffect(() => {
    if (useDetails?.instructions !== "completed") {
      setmodal_backdrop(true)
    } else {
      setmodal_backdrop(false)
    }
  }, [useDetails.instructions])
  const handleLogout = () => {
    localStorage.removeItem("authUser")
  }
  const updateInstructionState = async state => {
    console.log("working...")
    const stepDetails = {
      instructions: state,
    }
    console.log("stepDetails", stepDetails)
    await updateVet(useDetails._id, stepDetails)
    dispatch(getDetails(useDetails._id))
  }
  // const handleNextStep = () => {
  //   if (step < 3) {
  //     setStep(step + 1)
  //   }
  // }
  console.log("useDetails", useDetails)
  const [open, setOpen] = useState(false)
  const handleToggleDrawer = () => {
    setOpen(!open)
  }
  const handleCloseDrawer = () => {
    setOpen(false)
  }
  return (
    <React.Fragment>
      <InfoMessage open={open} handleCloseDrawer={handleCloseDrawer} />
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="30" />
                </span>
              </Link>
            </div>

            {/* <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button> */}
            <span className="display-name">
              Hello!{" "}
              <span className="display-actual-name">
                {useDetails?.fullName || "Doctor"},
              </span>
            </span>
          </div>

          <div className="d-flex align-items-center justify-content-center gap-3 mx-4">
            <div className="hover-container">
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => handleToggleDrawer()}
              >
                <img src={InfoIcon} alt="info" />
              </Link>
              <div className="popup">Info</div>
            </div>

            <div className="hover-container">
              <Link className="dropdown-item" to="/profile">
                <IconSVG icon="profile" />
              </Link>
              <div className="popup">Profile</div>
            </div>

            <div className="hover-container">
              <Link
                to="/login"
                onClick={handleLogout}
                className="dropdown-item"
              >
                <IconSVG icon="logout" />
              </Link>
              <div className="popup">Logout</div>
            </div>
          </div>

          {/* <ProfileMenu /> */}
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={"static"}
            id="staticBackdrop"
          >
            <div className="modal-body text-center">
              <div className="avatar-md mx-auto mb-2 rounded-circle">
                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                  <i className="mdi mdi-account"></i>
                </div>
              </div>
              <span className="display-name-modal text-center">
                Hello!{" "}
                <span className="display-actual-name">
                  {useDetails?.fullName}
                </span>
              </span>
              <br />
              {useDetails?.instructions === "step1" && (
                <div>
                  <p>
                    Customize your clinic's{" "}
                    <Link to="/timings">operational hours here</Link>. After
                    logging in, access the Administration Section to update
                    availability for the week or specific days.
                  </p>
                  <Button
                    onClick={() => updateInstructionState("step2")}
                    color="primary"
                  >
                    Next
                  </Button>
                </div>
              )}
              {useDetails?.instructions === "step2" && (
                <div>
                  <p>
                    {" "}
                    Here, you can tailor your clinic's{" "}
                    <Link to="/pricing">
                      Imaging and Lab tests pricing
                    </Link>{" "}
                    with ease. Once logged in, navigate to the Billing Section
                    to modify Pricing for different tests, ensuring accurate and
                    up-to-date lab pricing information.
                  </p>
                  <Button
                    onClick={() => updateInstructionState("step3")}
                    color="primary"
                  >
                    Next
                  </Button>
                </div>
              )}
              {useDetails?.instructions === "step3" && (
                <div>
                  <p>
                    This is where you can provide details about both{" "}
                    <Link to="/profile">yourself and your clinic</Link>. This
                    information will be utilized throughout the system,
                    including areas such as invoices, your appointment profile
                    within the Pet Parent app, and prescriptions.
                  </p>
                  <Button
                    onClick={() => updateInstructionState("completed")}
                    color="primary"
                  >
                    Finish
                  </Button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

export default Header
