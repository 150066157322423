import ButtonMain from "components/Common/Button"
import {
  addNewPetParent,
  getParentById,
  updatePatient,
} from "helpers/api_helper"
import { validateEmail, validatePhone } from "helpers/utils"
import * as Yup from "yup"
import { useFormik } from "formik"

import { use } from "i18next"
import React, { useEffect, useReducer, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  FormFeedback,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showMessage } from "components/Notification"
import ReactSelect from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getAllParents, getUserDetails, setGlobalLoading } from "store/slices"
import { Link } from "@material-ui/core"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
const CreatePatient = () => {
  //meta title
  document.title = "Create New Parent"
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})
  const [gender, setGender] = useState()
  const userDetails = useSelector(getUserDetails)
  // console.log(userDetails);
  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: details?.fullName || "",
      gender: details?.gender || "",
      phone: details?.phone || "",
      address: details?.address || "",
      notes: details?.notes || "",
      email: details?.email || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Full Name"),
      email: Yup.string().required("Please Enter email"),
      phone: Yup.string()
        .required("Please Enter phone number")
        .matches(/^\d{10}$/, "Phone number must be 10 digits"),
      address: Yup.string().required("Please Enter address"),
      // notes: Yup.string().required("Please Enter Notes"),
    }),
    onSubmit: async values => {
      if (!gender.value) {
        showMessage({ label: "Please Select gender", status: "info" })
        return
      }
      values.gender = gender.value
      values.createdBy = "vet"
      values.vetId = userDetails._id
      handleSubmit(values)
    },
  })

  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      if (id) {
        dispatch(setGlobalLoading(true))
      }

      try {
        const _detail = await getParentById({ patientId: id })
        setDetails(_detail.data)
        setGender({ label: _detail.data.gender, value: _detail.data.gender })
        dispatch(setGlobalLoading(false))
      } catch {
        // console.log("Error:", error)
        dispatch(setGlobalLoading(false))
      }
    }
    fetchData()
  }, [id])

  const handleSubmit = async values => {
    try {
      console.log("Submit", values)
      setIsLoading(true)
      let res
      if (!id) {
        res = await addNewPetParent(values)
      } else {
        res = await updatePatient(id, values)
      }

      if (res.status === 1 && id) {
        setIsLoading(false)
        showMessage({ label: res.message, status: "success" })
        history.push("/pet-parents")
      } else if (res.status === 1 && !id) {
        setIsLoading(false)
        showMessage({ label: res.message, status: "success" })
        history.push("/add-pet")
      } else {
        showMessage({
          label: res.message || "something went wrong",
          status: "error",
        })
      }
    } catch (error) {
      setIsLoading(false)
      showMessage({ label: error.response.data.data[0].msg, status: "error" })
    }
  }
  const [showFullResults, setShowFullResults] = useState(false)

  const parents = useSelector(getAllParents)
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredItems, setFilteredItems] = useState([])
  console.log("parents", parents)
  useEffect(() => {
    const filtered = parents?.filter(item => {
      const fullNameMatch = item?.fullName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
      const phoneMatch =
        typeof item?.phone === "number" &&
        item?.phone.toString().includes(searchTerm)
      return fullNameMatch || phoneMatch
    })
    setFilteredItems(filtered)
  }, [parents, searchTerm])
  console.log("FilteredItems", filteredItems)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="appointment-heading-div ">
            <Col
              className="d-flex align-items-center justify-content-start gap-2"
              x="auto"
            >
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">
                {id ? "Edit Parent" : "New Parent"}
              </h4>
            </Col>

            <Col
              className="d-flex align-items-center justify-content-between gap-4"
              xs="auto"
            >
              {/* <div>
                <div>
                  <FormGroup className="mb-4" row>
                    <div>
                      <Input
                        id="itemName"
                        name="itemName"
                        type="text"
                        className="form-control"
                        placeholder="Search by Pet Parent / Mobile Number"
                        value={searchTerm}
                        style={{
                          width: "444px",
                          border: "none",
                          borderBottom: "1px solid #B5B5B5",
                          borderRadius: "0",
                          paddingLeft: "0",
                          paddingBottom: "0",
                          backgroundColor: "transparent",
                        }}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {searchTerm && (
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "444px",
                          height: filteredItems.length > 0 ? "334px" : "60px",
                          background: "#FFFFFF",
                          zIndex: "1",
                          boxShadow: "0px 18px 30px 16px #00000008",
                          borderRadius: "12px",
                          overflowY: showFullResults ? "scroll" : "hidden",
                        }}
                      >
                        {filteredItems.length > 0 ? (
                          <>
                            {showFullResults ? (
                              <>
                                {filteredItems.map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/parent/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {filteredItems.slice(0, 3).map((ele, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <Link
                                      to={`/parent/${ele._id}`}
                                      style={{ color: "black" }}
                                    >
                                      {ele.fullName}
                                    </Link>
                                  </div>
                                ))}
                                {filteredItems.length > 3 && (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      marginTop: "auto",
                                      marginLeft: "27px",
                                      marginRight: "27px",
                                      marginBottom: "30px",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFullResults(true)}
                                  >
                                    <i className="fa fa-search me-1" />
                                    See All items
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              marginTop: "14px",
                              marginLeft: "24px",
                              textAlign: "center",
                            }}
                          >
                            No items found
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
            </Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumbs
            title="Patient"
            titleLink="/patients"
            breadcrumbItem={id ? "Edit Parent" : "New Parent"}
          /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="my-4  font-fontFamily">
                    <p className="fontSize-20 px-4 border-bottom">
                      Pet parent details
                    </p>
                  </CardTitle>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mb-3" row>
                          <Label className="col-form-label col-lg-4  text-right text-input-label font-fontFamily-400">
                            Full name<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              id="projectname"
                              name="fullName"
                              type="text"
                              className="form-control"
                              placeholder="Full name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fullName || ""}
                              invalid={
                                validation.touched.fullName &&
                                validation.errors.fullName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fullName &&
                            validation.errors.fullName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fullName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>

                        <FormGroup className="mb-3 row">
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                          >
                            Mobile Number<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              id="phone"
                              name="phone"
                              type="number"
                              className="form-control"
                              placeholder="Mobile Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={
                                validation.touched.phone &&
                                validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-right text-input-label font-fontFamily-400"
                          >
                            Email<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              name="email"
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="gender"
                            className="col-form-label col-lg-4  text-right text-input-label font-fontFamily-400"
                          >
                            Gender
                          </Label>
                          <div className="col-lg-8">
                            <ReactSelect
                              value={gender}
                              onChange={setGender}
                              options={[
                                { value: "Male", label: "Male" },
                                { value: "Female", label: "Female" },
                              ]}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-4  text-right text-input-label font-fontFamily-400"
                          >
                            Address<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-8">
                            <Input
                              id="address"
                              name="address"
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address &&
                            validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="justify-content-end">
                      <Col lg="12">
                        <FormGroup className="mb-3" row>
                          <Label
                            htmlFor="projectdesc"
                            className="col-form-label col-lg-2 text-right text-input-label font-fontFamily-400"
                          >
                            Notes<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-10">
                            <textarea
                              className="form-control"
                              id="projectdesc"
                              rows="8"
                              name="notes"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.notes || ""}
                              placeholder="Notes"
                              style={{ minHeight: 200 }}
                            />
                            {validation.touched.notes &&
                            validation.errors.notes ? (
                              <FormFeedback type="invalid">
                                {validation.errors.notes}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                        <div className="d-flex justify-content-end">
                          <ButtonMain
                            isLoading={isLoading}
                            type="submit"
                            className="btn btn-primary "
                          >
                            {id ? "Edit Patient" : "Add Patient"}
                          </ButtonMain>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreatePatient
