// import React, { useState, useEffect } from "react"
// import {
//   Row,
//   Col,
//   Modal,
//   ModalBody,
//   Form,
//   ModalHeader,
//   ModalFooter,
// } from "reactstrap"

// //redux
// import { useSelector, useDispatch } from "react-redux"

// // actions
// import {
//   updateDayAvailabilityByDayIdAndDocId,
//   updateStartAndEndTimeByDayIdAndDocId,
// } from "helpers/api_helper"
// import {
//   getAllTimings,
//   getDetails,
//   getTimingDetails,
//   getTimingsByDocId,
//   getUserDetails,
//   getVetCurrentTimings,
// } from "store/slices"
// import DaySchedule from "../pages/Administration/components/DaySchedule"

// import { showMessage } from "components/Notification"
// import { Carousel } from "react-responsive-carousel"
// import ButtonMain from "./Common/Button"

// const DoctorTimings = ({ isOpen, setModal, onChangeDate }) => {
//   //meta title
//   document.title = "Timing | SuPaw"
//   const userDetails = useSelector(getUserDetails)

//   const timingDetails = useSelector(getAllTimings)

//   const [nextDays, setNextDays] = useState(timingDetails.timings)

//   const dispatch = useDispatch()

//   const [isUpdatingButton, setIsUpdatingButton] = useState(false)

//   const [isFetchingData, setFetchingData] = useState(true)
//   const [idx] = useState(userDetails._id)

//   // *****************************
//   const [selectedDate, setSelectedDate] = useState(null)

//   useEffect(() => {
//     async function newLoad() {
//       if (localStorage.getItem("authUser")) {
//         setFetchingData(true)
//         const res = await dispatch(getVetCurrentTimings(idx))
//         setNextDays(res.timings)
//         setFetchingData(false)
//       }
//     }
//     newLoad()
//   }, [])
//   const onSelectedDate = (time, index) => {
//     setSelectedDate({ time: time, dateIndex: index })

//     onChangeDate({
//       day: timingDetails.timings[index].day,
//       time,
//       dateIndex: index,
//       timingId: timingDetails.timings[index]?._id,
//       slotId: timingDetails.timings[index]?.slots.find(el => el.time === time)
//         ?._id,
//     })
//     setModal(false)
//   }

//   return (
//     <Modal
//       isOpen={isOpen}
//       toggle={() => setModal(prevState => !prevState)}
//       size="lg"
//     >
//       <ModalHeader toggle={() => setModal(false)}>Select Time</ModalHeader>
//       <ModalBody className="py-3 px-5">
//         <Row>
//           {!isFetchingData ? (
//             <Carousel showArrows showIndicators>
//               {nextDays?.length
//                 ? nextDays.map((el, index) => {
//                     return (
//                       <DaySchedule
//                         isCurrentDay={index === 0}
//                         key={el._id}
//                         value={el}
//                         difference={30}
//                         isUpdatingButton={isUpdatingButton}
//                         setError={obj => handleError(el, obj)}
//                         isSelectOnly
//                         selectedDate={
//                           selectedDate?.dateIndex === index
//                             ? selectedDate.time
//                             : null
//                         }
//                         onSelectedDate={time => onSelectedDate(time, index)}
//                       />
//                     )
//                   })
//                 : "No Timings found"}
//             </Carousel>
//           ) : null}
//         </Row>
//       </ModalBody>
//     </Modal>
//   )
// }

// export default DoctorTimings

import React, { useState, useEffect } from "react"
import { Row, Modal, ModalBody, ModalHeader, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  getAllTimings,
  getUserDetails,
  getVetCurrentTimings,
} from "store/slices"
import DaySchedule from "../pages/Administration/components/DaySchedule"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // Required for Carousel styling

const DoctorTimings = ({ isOpen, setModal, onChangeDate }) => {
  document.title = "Timing | SuPaw"

  const userDetails = useSelector(getUserDetails)
  const timingDetails = useSelector(getAllTimings)
  const [nextDays, setNextDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [noSlotAvailable, setNoSlotAvailable] = useState(false) // State for the pop-up

  const dispatch = useDispatch()

  useEffect(() => {
    async function newLoad() {
      if (localStorage.getItem("authUser")) {
        const res = await dispatch(getVetCurrentTimings(userDetails._id))
        setNextDays(res.timings)
      }
    }
    newLoad()
  }, [dispatch, userDetails._id])

  const onSelectedDate = (time, index) => {
    setSelectedDate({ time, dateIndex: index })
    onChangeDate({
      day: timingDetails.timings[index].day,
      time,
      dateIndex: index,
      timingId: timingDetails.timings[index]?._id,
      slotId: timingDetails.timings[index]?.slots.find(el => el.time === time)
        ?._id,
    })
    setModal(false)
  }

  const handleNavigate = direction => {
    if (direction === "prev" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    } else if (direction === "next" && currentIndex < nextDays.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  // Check if today has any available slots when the modal opens
  useEffect(() => {
    if (isOpen && nextDays.length > 0) {
      const todaySlots = nextDays[0].slots
      const hasAvailableSlot = todaySlots.some(slot => slot.status === "")

      if (!hasAvailableSlot) {
        setNoSlotAvailable(true)
      } else {
        setNoSlotAvailable(false)
      }
    }
  }, [isOpen, nextDays])

  return (
    <>
      <div className={noSlotAvailable && isOpen ? "blur-background" : ""}>
        <Modal
          isOpen={isOpen}
          toggle={() => setModal(prevState => !prevState)}
          size="lg"
        >
          <ModalHeader toggle={() => setModal(false)}>Select Time</ModalHeader>
          <ModalBody className="py-3 px-5">
            <Row>
              {nextDays.length > 0 ? (
                <div className="w-100">
                  <Carousel
                    selectedItem={currentIndex}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {nextDays.map((el, index) => {
                      console.log(el, "ellll")
                      return (
                        <DaySchedule
                          isCurrentDay={index === 0}
                          key={el._id}
                          value={el}
                          difference={30}
                          isSelectOnly
                          selectedDate={
                            selectedDate?.dateIndex === index
                              ? selectedDate.time
                              : null
                          }
                          onSelectedDate={time => onSelectedDate(time, index)}
                        />
                      )
                    })}
                  </Carousel>
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      color="primary"
                      onClick={() => handleNavigate("prev")}
                      disabled={currentIndex === 0}
                    >
                      Previous
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => handleNavigate("next")}
                      disabled={currentIndex === nextDays.length - 1}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              ) : (
                "No Timings found"
              )}
            </Row>
          </ModalBody>
        </Modal>
      </div>

      {/* No Slots Available Pop-Up */}
      <Modal
        isOpen={noSlotAvailable && isOpen}
        toggle={() => setNoSlotAvailable(false)}
        centered // Centers the modal
      >
        <ModalHeader toggle={() => setNoSlotAvailable(false)}>
          No Slots Available
        </ModalHeader>
        <ModalBody className="text-center">
          <p>Today, no slots are available. Please select another day.</p>
          <Button color="primary" onClick={() => setNoSlotAvailable(false)}>
            OK
          </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DoctorTimings
