// import React, { useState } from "react"
// import { Line, Pie, Bar } from "react-chartjs-2"
// import { Container, Row, Col, Card, Button, ButtonGroup } from "react-bootstrap"

// const VeterinaryDashboard = () => {
//   const [timeFilter, setTimeFilter] = useState("monthly")

//   // Revenue by Service data
//   const revenueByServiceData = {
//     labels: {
//       daily: ["1", "2", "3", "4", "5", "6", "7"],
//       monthly: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "Jul",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec",
//       ],
//       yearly: ["2021", "2022", "2023"],
//     },
//     datasets: [
//       {
//         label: "Consultations",
//         data: {
//           daily: [200, 300, 400, 500, 600, 700, 800],
//           monthly: [
//             1200, 1900, 3000, 5000, 2400, 3800, 4300, 4200, 4900, 5200, 6000,
//             7000,
//           ],
//           yearly: [25000, 32000, 40000],
//         },
//         backgroundColor: "rgba(54, 162, 235, 0.2)", // Light Blue
//         borderColor: "rgba(54, 162, 235, 1)", // Darker Blue
//         borderWidth: 2,
//       },
//       {
//         label: "Primary Care",
//         data: {
//           daily: [150, 250, 350, 450, 550, 650, 750],
//           monthly: [
//             1500, 2200, 3100, 4500, 2900, 3700, 4000, 4100, 4600, 5200, 5500,
//             6200,
//           ],
//           yearly: [20000, 28000, 36000],
//         },
//         backgroundColor: "rgba(255, 206, 86, 0.2)", // Light Yellow
//         borderColor: "rgba(255, 206, 86, 1)", // Darker Yellow
//         borderWidth: 2,
//       },
//       {
//         label: "Surgery",
//         data: {
//           daily: [100, 200, 300, 400, 500, 600, 700],
//           monthly: [
//             1800, 2400, 3300, 5200, 3500, 4100, 4800, 4700, 5200, 5800, 6500,
//             7200,
//           ],
//           yearly: [30000, 40000, 50000],
//         },
//         backgroundColor: "rgba(255, 99, 132, 0.2)", // Light Red
//         borderColor: "rgba(255, 99, 132, 1)", // Darker Red
//         borderWidth: 2,
//       },
//     ],
//   }

//   // Revenue by Animal Type data
//   const revenueByAnimalTypeData = {
//     labels: {
//       daily: ["Dogs", "Cats", "Birds", "Reptiles", "Others"],
//       monthly: ["Dogs", "Cats", "Birds", "Reptiles", "Others"],
//       yearly: ["Dogs", "Cats", "Birds", "Reptiles", "Others"],
//     },
//     datasets: [
//       {
//         data: {
//           daily: [4500, 3000, 1500, 800, 500],
//           monthly: [12000, 8000, 3000, 1200, 900],
//           yearly: [300000, 250000, 80000, 30000, 15000],
//         },
//         backgroundColor: [
//           "rgba(255, 99, 132, 0.6)",
//           "rgba(54, 162, 235, 0.6)",
//           "rgba(255, 206, 86, 0.6)",
//           "rgba(75, 192, 192, 0.6)",
//           "rgba(153, 102, 255, 0.6)",
//         ],
//       },
//     ],
//   }

//   // Monthly Revenue data
//   const monthlyRevenueData = {
//     labels: {
//       daily: ["1", "2", "3", "4", "5", "6", "7"],
//       monthly: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "Jun",
//         "Jul",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec",
//       ],
//       yearly: ["2021", "2022", "2023"],
//     },
//     datasets: [
//       {
//         label: "Monthly Revenue",
//         data: {
//           daily: [200, 300, 400, 500, 600, 700, 800],
//           monthly: [
//             12000, 15000, 18000, 14000, 17000, 21000, 19000, 22000, 24000,
//             26000, 28000, 30000,
//           ],
//           yearly: [300000, 350000, 400000],
//         },
//         // backgroundColor: "rgba(54,162,235,0.2)",
//         // borderColor: "rgba(54,162,235,1)",
//         // borderWidth: 1,
//         backgroundColor: [
//           "rgba(54,162,235,0.7)",
//           "rgba(75,192,192,0.7)",
//           "rgba(153,102,255,0.7)",
//           "rgba(255,159,64,0.7)",
//           "rgba(255,99,132,0.7)",
//           "rgba(255,206,86,0.7)",
//         ],
//         borderColor: [
//           "rgba(54,162,235,1)",
//           "rgba(75,192,192,1)",
//           "rgba(153,102,255,1)",
//           "rgba(255,159,64,1)",
//           "rgba(255,99,132,1)",
//           "rgba(255,206,86,1)",
//         ],
//         borderWidth: 2,
//         hoverBackgroundColor: "rgba(255,206,86,0.8)",
//         hoverBorderColor: "rgba(255,206,86,1)",
//       },
//     ],
//   }

//   const chartOptions = {
//     maintainAspectRatio: false,
//     aspectRatio: 2,
//     scales: {
//       y: {
//         ticks: {
//           callback: value => "$" + value,
//         },
//       },
//     },
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: context => `${context.dataset.label}: $${context.raw}`,
//         },
//       },
//     },
//   }

//   // Tooltip specifically for Pie Chart
//   const pieChartOptions = {
//     maintainAspectRatio: false,
//     aspectRatio: 2,
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: context => {
//             const label = context.label || ""
//             const value = context.raw
//             return `${label}: $${value}`
//           },
//         },
//       },
//     },
//   }

//   const cardStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//     transition: "transform 0.2s ease-in-out",
//     borderRadius: "15px",
//     minHeight: "400px", // Ensure all cards have the same height
//   }

//   const handleFilterChange = filter => {
//     setTimeFilter(filter)
//   }

//   const getCurrentData = (data, type) =>
//     data.datasets.map(dataset => ({
//       ...dataset,
//       data: dataset.data[type],
//     }))

//   return (
//     <Container fluid style={{ padding: "1.3rem", marginTop: "-1.5rem" }}>
//       <Row>
//         <Col md={6}>
//           <Card
//             style={cardStyle}
//             onMouseEnter={e =>
//               (e.currentTarget.style.transform = "translateY(-5px)")
//             }
//             onMouseLeave={e =>
//               (e.currentTarget.style.transform = "translateY(0)")
//             }
//           >
//             <Card.Body>
//               <Card.Title>Revenue by Service</Card.Title>
//               <ButtonGroup className="mb-3">
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("daily")}
//                   active={timeFilter === "daily"}
//                 >
//                   Daily
//                 </Button>
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("monthly")}
//                   active={timeFilter === "monthly"}
//                 >
//                   Monthly
//                 </Button>
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("yearly")}
//                   active={timeFilter === "yearly"}
//                 >
//                   Yearly
//                 </Button>
//               </ButtonGroup>
//               <div style={{ height: "300px" }}>
//                 <Line
//                   data={{
//                     labels: revenueByServiceData.labels[timeFilter],
//                     datasets: getCurrentData(revenueByServiceData, timeFilter),
//                   }}
//                   options={chartOptions}
//                 />
//               </div>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card
//             style={cardStyle}
//             onMouseEnter={e =>
//               (e.currentTarget.style.transform = "translateY(-5px)")
//             }
//             onMouseLeave={e =>
//               (e.currentTarget.style.transform = "translateY(0)")
//             }
//           >
//             <Card.Body>
//               <Card.Title>Revenue by Animal Type</Card.Title>
//               <ButtonGroup className="mb-3">
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("daily")}
//                   active={timeFilter === "daily"}
//                 >
//                   Daily
//                 </Button>
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("monthly")}
//                   active={timeFilter === "monthly"}
//                 >
//                   Monthly
//                 </Button>
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("yearly")}
//                   active={timeFilter === "yearly"}
//                 >
//                   Yearly
//                 </Button>
//               </ButtonGroup>
//               <div style={{ height: "300px" }}>
//                 <Pie
//                   data={{
//                     labels: revenueByAnimalTypeData.labels[timeFilter],
//                     datasets: getCurrentData(
//                       revenueByAnimalTypeData,
//                       timeFilter
//                     ),
//                   }}
//                   options={pieChartOptions} // Use updated pie chart options
//                 />
//               </div>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       <Row>
//         <Col>
//           <Card
//             style={cardStyle}
//             onMouseEnter={e =>
//               (e.currentTarget.style.transform = "translateY(-5px)")
//             }
//             onMouseLeave={e =>
//               (e.currentTarget.style.transform = "translateY(0)")
//             }
//           >
//             <Card.Body>
//               <Card.Title>Monthly Revenue</Card.Title>
//               <ButtonGroup className="mb-3">
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("daily")}
//                   active={timeFilter === "daily"}
//                 >
//                   Daily
//                 </Button>
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("monthly")}
//                   active={timeFilter === "monthly"}
//                 >
//                   Monthly
//                 </Button>
//                 <Button
//                   variant="outline-secondary"
//                   onClick={() => handleFilterChange("yearly")}
//                   active={timeFilter === "yearly"}
//                 >
//                   Yearly
//                 </Button>
//               </ButtonGroup>
//               <div style={{ height: "300px" }}>
//                 <Bar
//                   data={{
//                     labels: monthlyRevenueData.labels[timeFilter],
//                     datasets: getCurrentData(monthlyRevenueData, timeFilter),
//                   }}
//                   options={chartOptions}
//                 />
//               </div>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   )
// }

// export default VeterinaryDashboard

import React from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./style/dashboard.scss"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ title, labels, data, boolean, thickness, type }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "", // Empty label for the dataset itself
        data: data,
        backgroundColor: "#6a0dad", // Purple color for the bars
        borderRadius: 2, // Rounded corners for bars
        barThickness: thickness, // Set bar thickness
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to take full height
    plugins: {
      legend: {
        display: false, // Hide the legend completely
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: () => "", // Empty title to remove the default label
          label: tooltipItem =>
            `${labels[tooltipItem.dataIndex]}: ${tooltipItem.raw}`, // Show the breed name and value
        },
        backgroundColor: "rgba(255, 255, 255, 0.9)", // White background for tooltip
        titleColor: "#333", // Title color in tooltip
        bodyColor: "#333", // Body color in tooltip
        borderColor: "#ddd", // Border color for tooltip
        borderWidth: 1,
        displayColors: false, // Hide color box in tooltip
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // Hides the x-axis labels
        },
        grid: {
          display: false, // Removes the gridlines on the x-axis
        },
      },
      y: {
        beginAtZero: true,
        max: Math.max(...data) + 10, // Dynamically adjust the y-axis max value based on data
        ticks: {
          stepSize: 10, // Step size for y-axis
        },
      },
    },
  }

  return (
    <div className={`card ${boolean ? "breed-count" : ""}`} style={{ borderRadius: "15px " }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
          {boolean && (
            <div><h6>{type || ""}</h6></div>
          )}
        </div>
        <div style={{ height: "87%" }}>
          <Bar data={chartData} options={options} />
        </div>
      </div>
    </div>
  )
}

export default BarChart
