import React from "react"
import { Button, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DashboardHeader = ({ setTimeRange, timeRange, handleApplyRange }) => {

  const handleDateSelect = (type, date) => {
    switch(type) {
      case "START":
        setTimeRange( prevData => ({...prevData, startDate: new Date(date).toLocaleDateString("in")}))
        break
      case "END":
        setTimeRange( prevData => ({...prevData, endDate: new Date(date).toLocaleDateString("in")}))
        break
      default:
        break
    }
  }

  return (
    <div
      className="d-flex flex-wrap justify-content-between align-items-center business-header mb-4"
      style={{ paddingTop: "78px" }}
    >
      <h2 className="mb-3 mb-md-0" style={{ fontWeight: "bold" }}>
        Business Dashboard
      </h2>

      <div className="d-flex flex-wrap align-items-center">
        <div className="d-flex align-items-center me-2">
          <Form.Group controlId="startDate">
            <DatePicker
              selected={new Date(timeRange.startDate.split("/").reverse().join("-"))}
              onChange={date => handleDateSelect("START", date)}
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </Form.Group>
          <span className="mx-2">to</span>
          <Form.Group controlId="endDate">
            <DatePicker
              selected={new Date(timeRange.endDate.split("/").reverse().join("-"))}
              onChange={date => handleDateSelect("END", date)}
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </Form.Group>
        </div>

        {/* <Button variant="primary" className="apply-btn mx-2">
          Apply Range
        </Button> */}
        <button className="applyBtn" type="button" onClick={handleApplyRange}> Apply Range</button>
      </div>
      {/* <button className="applyBtn"> + Create</button> */}
    </div>
  )
}

export default DashboardHeader
