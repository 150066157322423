// import PropTypes from "prop-types"
// import React from "react"
// import { connect, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"

// //i18n
// import { withTranslation } from "react-i18next"
// import SidebarContent from "./SidebarContent"

// import { Link } from "react-router-dom"

// import logo from "../../assets/images/supaw-vets.svg"
// import logoLightPng from "../../assets/images/supaw-vets.svg"
// import logoDark from "../../assets/images/supaw-vets.svg"
// import smallLogo from "../../assets/images/logo-1.png"
// import LeftArrow from "../../assets/icons/ArrowLeft.png"
// import { useDispatch } from "react-redux"
// import { changeSidebarType } from "store/actions"
// const Sidebar = props => {
//   const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)
//   // console.log(leftSideBarType)

//   const dispatch = useDispatch()

//   function tToggle() {
//     var body = document.body
//     if (window.screen.width <= 998) {
//       body.classList.toggle("sidebar-enable")
//     } else {
//       body.classList.toggle("vertical-collpsed")
//       body.classList.toggle("sidebar-enable")
//       if (leftSideBarType === "default") {
//         dispatch(changeSidebarType("condensed"))
//       } else {
//         dispatch(changeSidebarType("default"))
//       }
//     }
//   }

//   const rotateImgStyle = {
//     transform: "rotate(180deg)",
//   }
//   return (
//     <React.Fragment>
//       <div className="vertical-menu">
//         <div className="navbar-brand-box">
//           <div className="logo logo-dark">
//             <span className="logo-sm ----">
//               <div className="d-flex-column justify-content-center align-items-center">
//                 <img
//                   src={LeftArrow}
//                   alt=""
//                   height="30"
//                   onClick={() => {
//                     tToggle()
//                   }}
//                   style={rotateImgStyle}
//                 />
//                 {/* <img src={smallLogo} alt="" height="22" /> */}
//               </div>
//             </span>
//             <span className="logo-lg">
//               <div className="d-flex align-items-center justify-content-between gap-4 mt-3">
//                 <img src={logoDark} alt="" height="40" />
//                 <img
//                   src={LeftArrow}
//                   alt=""
//                   height="30"
//                   onClick={() => {
//                     tToggle()
//                   }}
//                 />
//               </div>
//             </span>
//           </div>

//           <Link to="/" className="logo logo-light">
//             <span className="logo-sm ---333">
//               <img src={logoLightPng} alt="" height="30" />
//             </span>
//             <span className="logo-lg">
//               <img src={logoLightPng} alt="" height="30" />
//             </span>
//           </Link>
//         </div>
//         <div data-simplebar className="h-100">
//           {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
//         </div>
//         <div className="sidebar-background"></div>
//       </div>
//     </React.Fragment>
//   )
// }

// Sidebar.propTypes = {
//   type: PropTypes.string,
// }

// const mapStatetoProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }
// export default connect(
//   mapStatetoProps,
//   {}
// )(withRouter(withTranslation()(Sidebar)))

// import PropTypes from "prop-types"
// import React, { useEffect, useRef, useState } from "react"
// import { connect, useSelector } from "react-redux"
// import { withRouter } from "react-router-dom"
// import { withTranslation } from "react-i18next"
// import SidebarContent from "./SidebarContent"
// import { Link } from "react-router-dom"
// import logo from "../../assets/images/supaw-vets.svg"
// import logoLightPng from "../../assets/images/supaw-vets.svg"
// import logoDark from "../../assets/images/supaw-vets.svg"
// import LeftArrow from "../../assets/icons/ArrowLeft.png"
// import { useDispatch } from "react-redux"
// import { changeSidebarType } from "store/actions"

// const Sidebar = props => {
//   const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)
//   const dispatch = useDispatch()

//   const prevWidthRef = useRef(window.innerWidth)

//   const handleResize = () => {
//     const currentWidth = window.innerWidth

//     if (currentWidth <= 998 && prevWidthRef.current > 998) {
//       // Width crossed below 998
//       tToggle()
//     } else if (currentWidth > 998 && prevWidthRef.current <= 998) {
//       // Width crossed above 998
//       tToggle()
//     }

//     prevWidthRef.current = currentWidth
//   }

//   useEffect(() => {
//     // Set initial state
//     handleResize()

//     // Add event listener for resize
//     window.addEventListener("resize", handleResize)

//     // Cleanup event listener
//     return () => {
//       window.removeEventListener("resize", handleResize)
//     }
//   }, [])

//   function tToggle() {
//     var body = document.body
//     if (window.innerWidth <= 998) {
//       body.classList.add("sidebar-enable")
//       body.classList.remove("vertical-collpsed")
//     } else {
//       body.classList.add("vertical-collpsed")
//       body.classList.remove("sidebar-enable")
//       if (leftSideBarType === "default") {
//         dispatch(changeSidebarType("condensed"))
//       } else {
//         dispatch(changeSidebarType("default"))
//       }
//     }
//   }

//   const rotateImgStyle = {
//     transform: "rotate(180deg)",
//   }

//   return (
//     <React.Fragment>
//       <div className="vertical-menu">
//         <div className="navbar-brand-box">
//           <div className="logo logo-dark">
//             <span className="logo-sm ----">
//               <div className="d-flex-column justify-content-center align-items-center">
//                 <img
//                   src={LeftArrow}
//                   alt=""
//                   height="30"
//                   onClick={() => {
//                     tToggle()
//                   }}
//                   style={rotateImgStyle}
//                 />
//               </div>
//             </span>
//             <span className="logo-lg">
//               <div className="d-flex align-items-center justify-content-between gap-4 mt-3">
//                 <img src={logoDark} alt="" height="40" />
//                 <img
//                   src={LeftArrow}
//                   alt=""
//                   height="30"
//                   onClick={() => {
//                     tToggle()
//                   }}
//                 />
//               </div>
//             </span>
//           </div>

//           <Link to="/" className="logo logo-light">
//             <span className="logo-sm ---333">
//               <img src={logoLightPng} alt="" height="30" />
//             </span>
//             <span className="logo-lg">
//               <img src={logoLightPng} alt="" height="30" />
//             </span>
//           </Link>
//         </div>
//         <div data-simplebar className="h-100">
//           {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
//         </div>
//         <div className="sidebar-background"></div>
//       </div>
//     </React.Fragment>
//   )
// }

// Sidebar.propTypes = {
//   type: PropTypes.string,
// }

// const mapStatetoProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }

// export default connect(
//   mapStatetoProps,
//   {}
// )(withRouter(withTranslation()(Sidebar)))

import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"
import logo from "../../assets/images/supaw-vets.svg"
import logoLightPng from "../../assets/images/supaw-vets.svg"
import logoDark from "../../assets/images/supaw-vets.svg"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import { useDispatch } from "react-redux"
import { changeSidebarType } from "store/actions"

const Sidebar = props => {
  const leftSideBarType = useSelector(state => state.Layout.leftSideBarType)
  const dispatch = useDispatch()

  const prevWidthRef = useRef(window.innerWidth)

  function Toggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
      if (leftSideBarType === "default") {
        dispatch(changeSidebarType("condensed"))
      } else {
        dispatch(changeSidebarType("default"))
      }
    }
  }

  const handleResize = () => {
    const currentWidth = window.innerWidth

    if (currentWidth >= 998 && prevWidthRef.current < 998) {
      // Width crossed below 998
      // Ensure "sidebar-enable" is added and "vertical-collpsed" is removed
      tToggle(true)
    } else if (currentWidth < 998 && prevWidthRef.current >= 998) {
      // Width crossed above 998
      // Ensure "vertical-collpsed" is added and "sidebar-enable" is removed
      tToggle(false)
    }

    prevWidthRef.current = currentWidth
  }

  // const tToggle = isBelow998 => {
  //   var body = document.body
  //   if (isBelow998) {
  //     // Apply changes for width <= 998
  //     body.classList.add("sidebar-enable")
  //     body.classList.remove("vertical-collpsed")
  //   } else {
  //     // Apply changes for width > 998
  //     body.classList.add("vertical-collpsed")
  //     body.classList.remove("sidebar-enable")
  //     if (leftSideBarType === "default") {
  //       dispatch(changeSidebarType("condensed"))
  //     } else {
  //       dispatch(changeSidebarType("default"))
  //     }
  //   }
  // }

  const tToggle = isBelow998 => {
    const body = document.body

    if (isBelow998) {
      // Apply changes for width <= 998
      dispatch(changeSidebarType("default"))
      body.classList.add("sidebar-enable")
      body.classList.remove("vertical-collpsed")
    } else {
      // Apply changes for width > 998
      body.classList.add("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      dispatch(changeSidebarType("condensed"))

      // Update sidebar type based on condition
      // if (leftSideBarType === "default") {
      //   dispatch(changeSidebarType("condensed"))
      // } else {
      // dispatch(changeSidebarType("default"))
      // }

      // Force re-render of sidebar component without a full page reload
      // const sidebar = document.getElementById("sidebar") // Make sure you have an ID or a way to select the sidebar
      // if (sidebar) {
      //   sidebar.style.display = "none" // Hide the sidebar temporarily
      //   setTimeout(() => {
      //     sidebar.style.display = "block" // Show the sidebar again
      //   }, 0) // Delay of 0 ms will force a re-render
      // }
    }
  }

  useEffect(() => {
    // Set initial state
    handleResize()

    // Add event listener for resize
    window.addEventListener("resize", handleResize)

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const rotateImgStyle = {
    transform: "rotate(180deg)",
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <div className="logo logo-dark">
            <span className="logo-sm ----">
              <div className="d-flex-column justify-content-center align-items-center">
                <img
                  src={LeftArrow}
                  alt=""
                  height="30"
                  onClick={() => {
                    // tToggle(window.innerWidth <= 998)
                    Toggle()
                  }}
                  style={rotateImgStyle}
                />
              </div>
            </span>
            <span className="logo-lg">
              <div className="d-flex align-items-center justify-content-between gap-4 mt-3">
                <img src={logoDark} alt="" height="40" />
                <img
                  src={LeftArrow}
                  alt=""
                  height="30"
                  onClick={() => {
                    tToggle(window.innerWidth <= 998)
                  }}
                />
              </div>
            </span>
          </div>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm ---333">
              <img src={logoLightPng} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="30" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
