import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./dashboard.scss"
import {
  Card,
  Row,
  Col,
  ListGroup,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap"
import { Doughnut, Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js"
import { FaCaretDown } from "react-icons/fa"
import patientImage from "../../assets/images/patient.svg"
import consultantImage from "../../assets/images/consultant.svg"
import vaccination from "../../assets/images/vaccinnation.svg"
import BarChart from "components/Dashboard/BarChart"
import DoctorSchedule from "components/Dashboard/ScheduelList"
import UpcomingBirthdays from "../../components/Dashboard/UpcomingBirthday"
import DashBoardHeader from "../../components/Dashboard/DashboardHeader"
import { DonutChart } from "components/Dashboard/DonutChart"
import { fetchDashboardApi, fetchDashboardApiTwo } from "helpers/api_helper"
import { useDispatch, useSelector } from "react-redux"
import { getUserDetails, setGlobalLoading } from "store/slices"

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
)

function Dashboard() {
  const timeSlots = ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00'];

  const [appointment, setAppointment] = useState([]);
  const [firstApiResponse, setFirstApiResponse] = useState({});
  const [secondApiResponse, setSecondApiResponse] = useState({});
  const [appointmentType, setAppointmentType] = useState("Dog");
  const [timeRange, setTimeRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString("in"),
    endDate: new Date().toLocaleDateString("in") 
  });
  const [appointmentStatus, setAppointmentStatus] = useState("completed");

  const userDetails = useSelector(getUserDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    dashboardData();
  }, [appointmentType, appointmentStatus]);
  
  async function dashboardData() {
    dispatch(setGlobalLoading(true))
    const params = {
      "vetId": userDetails._id,
      "startDate": timeRange.startDate.split("/").reverse().join("-"),
      "endDate": timeRange.endDate.split("/").reverse().join("-"),
      "appointmentStatus": appointmentStatus,
      "petType": appointmentType
  }
    const [response, secondResponse] = await Promise.all([
      fetchDashboardApi(params),
      fetchDashboardApiTwo(
        params.petType, 
        userDetails._id, 
        timeRange.startDate.split("/").reverse().join("-"), 
        timeRange.endDate.split("/").reverse().join("-")
      )
    ])

    if(secondResponse && secondResponse.status) {
      setSecondApiResponse(secondResponse.data?.data);
    }

    if(response && response.status) {
      setFirstApiResponse(response.data);
      if(response.data.appointments && response.data.appointments.length) {
        setAppointment(response.data.appointments);
      }
    }
    dispatch(setGlobalLoading(false))
  }

  const handleApplyRange = () => {
    dashboardData();
  }


  return (
    <div className="px-3 " style={{ backgroundColor: "#F0F2F3" }}>
      <DashBoardHeader setTimeRange={setTimeRange} timeRange={timeRange} handleApplyRange={handleApplyRange}/>

      {/* First Row: Stats */}
      <Row>
        <Col lg={9}>
          <Row className="mb-3">
            <Col sm={12} md={4}>
              <StatsCard title="New Patients" value={firstApiResponse?.newPatients} icon={patientImage} />
            </Col>
            <Col sm={12} md={4}>
              <StatsCard
                title="Consultations"
                value={firstApiResponse?.consultations}
                icon={consultantImage}
              />
            </Col>
            <Col sm={12} md={4}>
              <StatsCard
                title="Vaccinations Due"
                value={firstApiResponse?.vaccinationsDue}
                icon={vaccination}
              />
            </Col>
          </Row>

          {/* Second Row: Charts */}
          <Row className="mb-3">
            <Col xs={12} sm={6} lg={4}>
              <DonutChart
                title="Gender"
                data={firstApiResponse?.genderDistribution?.map((pet) => pet.count) || []}
                labels={firstApiResponse?.genderDistribution?.map((pet) => pet.gender)  || []}
                colors={["#E89F23", "#652B88"]}
                text={"Patients"}
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <DonutChart
                title="Patient types"
                data={firstApiResponse?.petTypeDistribution?.map((pet) => pet.count) || []}
                labels={firstApiResponse?.petTypeDistribution?.map((pet) => pet.petType) || []}
                colors={["#652B88", "#E89F23", "#E75C25", "#D7D7D7"]}
                text={"Pets"}
              />
            </Col>
            <Col xs={12} lg={4}>
              <BarChart
                title="Top 5 Breeds"
                labels={secondApiResponse?.petData?.map((pet) => pet._id) || []}
                data={secondApiResponse?.petData?.map((pet) => pet.count) || []}
                boolean={true}
                thickness={15}
                type={appointmentType}
              />
            </Col>
          </Row>
        </Col>

        {/* Appointments List */}
        <Col lg={3}>
          <AppointmentsList className="flex-grow-1" 
            appointmentList={appointment}
            setAppointmentType={setAppointmentType}
            appointmentType={appointmentType}
            setAppointmentStatus={setAppointmentStatus}
            appointmentStatus={appointmentStatus}
          />
        </Col>
      </Row>

      {/* Third Row: Doctor's Schedule and Upcoming Birthdays */}
      <Row className="mb-3">
        <Col xs={12} md={8}>
          <DoctorSchedule
            title="Doctor's Schedule"
            timeSlots={timeSlots}
            appointments={secondApiResponse?.doctorsAppointment}
          />
        </Col>
        <Col xs={12} md={4}>
          <UpcomingBirthdays birthdays={firstApiResponse?.petsWithUpcomingBirthdays}/>
        </Col>
      </Row>

      {/* Fourth Row: Lab Tests and Imaging Tests */}
      <Row className="mb-5">
        <Col xs={12} md={6}>
          <BarChart
            title="Top 5 - Lab Tests"
            labels={secondApiResponse?.topLabTests?.map((test) => test._id) || []}
            data={secondApiResponse?.topLabTests?.map((test) => test.count) || []}
            boolean={false}
            thickness={35}
          />
        </Col>
        <Col xs={12} md={6}>
          <BarChart
            title="Top 5 – Imaging Tests"
            labels={secondApiResponse?.topImagingTests?.map((test) => test._id) || []}
            data={secondApiResponse?.topImagingTests?.map((test) => test.count) || []}
            boolean={false}
            thickness={35}
          />
        </Col>
      </Row>
    </div>
  )
}

function StatsCard({ title, value, icon }) {
  return (
    <Card
      className="custom-card shadow-sm border-light"
      style={{ borderRadius: "15px" }}
    >
      <Card.Body className="d-flex align-items-center">
        <img src={icon} className="me-3" style={{ width: "40px" }} alt="icon" />
        <div>
          <Card.Title className="mb-0">{title}</Card.Title>
          <Card.Text className="display-6">{value}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

function AppointmentsList({ appointmentList, setAppointmentType, appointmentType, setAppointmentStatus, appointmentStatus}) {

  return (
    <Card className="mb-4 appointment-card" style={{ borderRadius: "15px", height: "490px", overflow: "hidden" }}>
      <Card.Body>
        {/* Header */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="card-title">Appointments</h5>
          <span style={{ color: "#F26D21", fontWeight: "bold" }}>• Dog</span>
        </div>

        {/* Dropdowns */}
        <div className="d-flex justify-content-between mb-3">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="light" className="dropdown-toggle">
              {appointmentType}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ marginTop: "35px" }}>
              {/* <Dropdown.Item
                onClick={() => setAppointmentType("All Appointments")}
              >
                All Appointments
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => setAppointmentType("Dog")}>
                Dog
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setAppointmentType("Cat")}>
                Cat
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="light" className="dropdown-toggle">
              {appointmentStatus}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ marginTop: "35px" }}>
            <Dropdown.Item onClick={() => setAppointmentStatus("completed")}>
                Completed
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setAppointmentStatus("open")}>
                Open
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setAppointmentStatus("scheduled")}>
                Scheduled
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Appointment List */}
        <ListGroup
          variant="flush"
          style={{ overflowY: "auto", maxHeight: "350px" }}
        >
          { appointmentList && appointmentList.length ? appointmentList?.map((appointment, index) => (
            <ListGroup.Item key={index} className="d-flex align-items-center">
              <div
                style={{
                  borderLeft: "5px solid #F26D21",
                  paddingLeft: "10px",
                  width: "100%",
                }}
              >
                <div>
                  <strong>{appointment?.petObjectId?.fullName}</strong> - {appointment?.visitType}
                </div>
                <small className="text-muted">
                  {appointment.time} • 30min • {new Date(appointment?.date).toLocaleDateString()}
                </small>
              </div>
            </ListGroup.Item>
          )) : (<div className="flex-center">
            <h6>No appointment found.</h6>
          </div>)}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

export default Dashboard
