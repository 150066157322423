import React, { useState, useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  CardText,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import { Link, useHistory, useParams, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// action
import ReactSelect from "react-select"
import ButtonMain from "components/Common/Button"
import {
  addAppointment,
  fetchAppointmentsBasedOnId,
  getAllPetsByParentId,
  getPetBreed,
  getPetById,
  updateAppointment,
  updateStartAndEndTimeByDayIdAndDocId,
} from "helpers/api_helper"
import {
  fetchAppointmentTypes,
  fetchPetTypes,
  getAllParents,
  getParentList,
  getPricingDetails,
  getServices,
  getTimingDetails,
  getTodaysAppointments,
  getTypes,
  getUserDetails,
  getpricing,
  setGlobalLoading,
} from "store/slices"
import {
  dateFormatAsdddMMYYYY,
  getAppointmentType,
  modifyAppointmentType,
  modifyParentType,
  reasonOptions,
  timestampToDatetimeInputString,
} from "helpers/utils"
import { showMessage } from "components/Notification"
import DoctorTimings from "components/DoctorTimings"
import SearchBarComponent from "components/Common/SearchBar"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
const SUBTYPE = [
  {
    label: "General",
    value: "general",
  },
  {
    label: "Surgery",
    value: "surgery",
  },
  { label: "Follow up", value: "follow-up" },
]

const CLINICLOCATION = [
  {
    label: "Mumbai",
    value: "mumbai",
  },
  {
    label: "Delhi",
    value: "delhi",
  },
  { label: "Noida", value: "noida" },
]

const UserProfile = () => {
  //meta title
  document.title = "Profile | SuPaw"
  const userDetails = useSelector(getUserDetails)
  const appointmentType = useSelector(getServices)
  console.log("appointmentType", appointmentType)

  const dummyAppointment = useSelector(getpricing)
  const filterPricingOptions = dummyAppointment.filter(
    ele => ele.category.pricingCategoryName === "Consultation Pricing"
  )
  console.log("filterPricingOptions", filterPricingOptions)
  const parentsList = useSelector(getAllParents)
  const petType = useSelector(getTypes)
  const timingDetails = useSelector(getTimingDetails)
  const authDetails = userDetails

  const dispatch = useDispatch()
  const { id } = useParams() // For Editing appointment

  const { petId } = useParams()
  const history = useHistory()
  const [isValidAppointment, setIsValidAppointment] = useState(true)
  const [appointment, setAppointment] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  // const

  const [isLoading, setIsLoading] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const [visitType, setVisitType] = useState(null)
  const [ReasonType, setReasonType] = useState(null)
  console.log(visitType)
  const [petTypes, setPetTypes] = useState({})
  const [breeds, setBreeds] = useState([])
  const [selectedBreed, setSelectedBreed] = useState({})

  const [selectedPet, setSelectedPet] = useState({})
  const [allPets, setAllPets] = useState([])
  const [currentPet, setCurrentSelectedPet] = useState({})
  const [isFetchingPets, setIsFetchingPets] = useState(false)
  const [addPet, setAddPet] = useState(false)
  const [pricing, setPricing] = useState(null)
  const [selectedParent, setSelectedParent] = useState({})

  const [subType, setSubType] = useState(SUBTYPE[0])
  const [clinicLocation, setClinicLocation] = useState(CLINICLOCATION[0])
  const [isDateModalOpen, onDateClick] = useState(false)

  const reducer = (state, action) => {
    switch (action.type) {
      case "edit":
        return { ...state, [action.payload.name]: action.payload.value }
      default:
        return state
    }
  }

  //function to fetch pricing options
  useEffect(() => {
    async function fetchPricingData() {
      if (userDetails?._id) {
        dispatch(setGlobalLoading(true))
        await dispatch(getPricingDetails({ vetId: userDetails?._id }))
        dispatch(setGlobalLoading(false))
      }
    }
    fetchPricingData()
  }, [dispatch])

  const [values, dispatchReducer] = useReducer(reducer, {
    notes: "",
    date: new Date(),
  })
  const stateChange = name => e => {
    const _value = e.target.value
    dispatchReducer({ type: "edit", payload: { name, value: _value } })
  }
  const handleSetDataForEdit = async data => {
    var x = timestampToDatetimeInputString(Date.now(data.date))
    stateChange("date")({ target: { value: x } })

    await handleSelectedParent({
      label: `${data.parentId.fullName} (${data.parentId.email})`,
      value: data.parentId._id,
    })
    handleSelectedPet({
      label: data.petObjectId.fullName,
      value: data.petObjectId._id,
    })
    setCurrentSelectedPet(data.petObjectId)
    const apptDetails = filterPricingOptions.find(
      el => el.name === data.visitType
    )
    setVisitType({ label: apptDetails?.name, value: apptDetails?._id })
    stateChange("notes")({ target: { value: data.notes } })
  }
  const handlePetTypeChange = async item => {
    setPetTypes(item)
    const selected = petType.find(ele => ele._id === item.value)
    const response = await getPetBreed(selected.id)
    if (response.status == 1) {
      setBreeds(modifyAppointmentType(response.data))
    }
  }
  const handleSelectedParent = async item => {
    setSelectedParent(item)
    setIsFetchingPets(true)
    const response = await getAllPetsByParentId(item.value)
    if (response.status == 1) {
      if (response.data?.length) {
        setAllPets(response.data)
        setIsFetchingPets(false)
        setAddPet(false)
      } else {
        showMessage({
          label: `No pets found for ${item.label} parent`,
          status: "info",
        })
        setAddPet(true)
        setAllPets([])
      }
    }
    setSelectedPet(null)
    setIsFetchingPets(false)
    return response.data
  }
  const handleSelectedPet = async (item, petlist = null) => {
    setSelectedPet(item)
    const selected = [...(petlist?.length ? petlist : allPets)].find(
      ele => ele._id === item.value
    )
    setCurrentSelectedPet(selected)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (!selectedDate) {
      showMessage({
        label: `Please select date`,
        status: "warning",
      })
      return
    }
    if (!currentPet._id) {
      showMessage({
        label: `Please select pet parent and pet`,
        status: "warning",
      })
      return
    }
    // if (!values.notes) {
    //   showMessage({
    //     label: `Please add notes`,
    //     status: "warning",
    //   })
    //   return
    // }
    if (!visitType) {
      showMessage({
        label: `please select visit type`,
        status: "warning",
      })
      return
    }
    const date = new Date(selectedDate.day)
    const _time = selectedDate.time.split(":")
    date.setHours(Number(_time[0]) + 5)
    date.setMinutes(Number(_time[1]) + 30)

    const obj = {
      date: timestampToDatetimeInputString(date),
      parentId: currentPet.parentId,
      doctorId: authDetails._id,
      petObjectId: id ? currentPet : currentPet._id,
      notes: values.notes,
      petType: currentPet.petType,
      clinicLocation: "Temp",
      subType: "temp",
      breed: currentPet.petBreed,
      type: "visit",
      visitType: visitType.label,
      time: selectedDate?.time,
      timingId: selectedDate?.timingId,
      slotId: selectedDate?.slotId,
      reasonForVisit: ReasonType.value,
      // status:subType.label,
      cost: visitType?.pricing,
    }
    console.log(obj)
    // return;
    setIsLoading(true)
    try {
      let response = {}
      if (id) {
        response = await updateAppointment(id, obj)
      } else {
        response = await addAppointment(obj)
      }
      console.log("response: ", response)

      if (response.status == 1) {
        showMessage({
          label: response.message,
          status: "success",
        })

        if (petId) {
          history.push(`/parent/${currentPet.parentId}`)
        } else {
          dispatch(getTodaysAppointments())
          history.push(`/appointments`)
        }

        dispatch(getTodaysAppointments())
      }
    } catch (error) {
      showMessage({
        label: error.response.data.message,
        status: "error",
      })
    }
    setIsLoading(false)
  }
  const handleSubmitCheckIn = async e => {
    e.preventDefault()
    if (!selectedDate) {
      showMessage({
        label: `Please select date`,
        status: "warning",
      })
      return
    }
    if (!currentPet._id) {
      showMessage({
        label: `Please select pet parent and pet`,
        status: "warning",
      })
      return
    }
    // if (!values.notes) {
    //   showMessage({
    //     label: `Please add notes`,
    //     status: "warning",
    //   })
    //   return
    // }
    if (!visitType) {
      showMessage({
        label: `please select visit type`,
        status: "warning",
      })
      return
    }
    const date = new Date(selectedDate.day)
    const _time = selectedDate.time.split(":")
    date.setHours(Number(_time[0]) + 5)
    date.setMinutes(Number(_time[1]) + 30)

    const obj = {
      date: timestampToDatetimeInputString(date),
      parentId: currentPet.parentId,
      doctorId: authDetails._id,
      petObjectId: id ? currentPet : currentPet._id,
      notes: values.notes,
      petType: currentPet.petType,
      clinicLocation: "Temp",
      subType: "temp",
      breed: currentPet.petBreed,
      type: "visit",
      visitType: visitType.label,
      time: selectedDate?.time,
      timingId: selectedDate?.timingId,
      slotId: selectedDate?.slotId,
      reasonForVisit: ReasonType.value,
      // status:subType.label,
      cost: visitType?.pricing,
    }
    console.log(obj)
    // return;
    setIsLoading1(true)
    try {
      let response = {}
      if (id) {
        response = await updateAppointment(id, obj)
      } else {
        response = await addAppointment(obj)
      }

      if (response.status == 1) {
        showMessage({
          label: response.message,
          status: "success",
        })
        // let _slots = [
        //   ...(timingDetails?.timings[selectedDate?.dateIndex]?.slots || []),
        // ]
        // const updatedSlots = _slots.map(el => {
        //   if (el.time === selectedDate.time) {
        //     return {
        //       ...el,
        //       status: "BLOCKED",
        //     }
        //   } else {
        //     return el
        //   }
        // })
        // const res = await updateStartAndEndTimeByDayIdAndDocId({
        //   doctorId: authDetails._id,
        //   dayId: timingDetails.timings[selectedDate.dateIndex]._id,
        //   startTime: timingDetails.timings[selectedDate.dateIndex].startTime,
        //   endTime: timingDetails.timings[selectedDate.dateIndex].endTime,
        //   slots: updatedSlots,
        // })

        console.log(response, "responce")

        if (petId) {
          history.push(`/parent/${currentPet.parentId}`)
        } else {
          if (id) {
            history.push(`/checkin/${id}`)
          } else {
            history.push(`/checkin/${response.data._id}`)
          }
        }

        dispatch(getTodaysAppointments())
      }
    } catch (error) {
      showMessage({
        label: error.response.data.message,
        status: "error",
      })
    }
    setIsLoading1(false)
  }

  const onChangeDate = dateObj => {
    console.log(dateObj)
    setSelectedDate(dateObj)
  }

  useEffect(() => {
    async function newLoad() {
      dispatch(setGlobalLoading(true))
      dispatch(fetchAppointmentTypes())
      dispatch(fetchPetTypes())
      setGlobalLoading(true)
      dispatch(getParentList({ doctorId: userDetails._id }))
      if (id) {
        try {
          const response = await fetchAppointmentsBasedOnId(id)
          console.log(response, "responce")
          console.log(
            response?.data?.reasonForVisit,
            "response?.data?.reasonForVisit"
          )
          if (response.status === 1) {
            setAppointment(response.data)
            setIsValidAppointment(true)
            handleSetDataForEdit(response.data)
            setPricing(response?.data.cost)
            // setReasonType(response?.data?.reasonForVisit)
            handleVisitTypeChange(response?.data?.reasonForVisit)
            console.log("response", response.data)
            setSelectedDate({
              day: new Date(response.data.date),
              time: response.data.time,
            })
          } else {
            setIsValidAppointment(false)
          }
          dispatch(setGlobalLoading(false))
        } catch (error) {
          setIsValidAppointment(false)
          dispatch(setGlobalLoading(false))
        }
      }

      dispatch(setGlobalLoading(false))
    }
    newLoad()
    // setVisitType(modifyAppointmentType(filterPricingOptions)[0])
  }, [dispatch, id])

  useEffect(() => {
    async function newLoad() {
      if (petId) {
        try {
          dispatch(setGlobalLoading(true))
          const response = await getPetById(petId)
          if (response.status === 1) {
            const { data } = response
            const petsList = await handleSelectedParent({
              label: `${data.parentId.fullName} (${data.parentId.email})`,
              value: data.parentId._id,
            })
            handleSelectedPet(
              {
                label: data.fullName,
                value: data._id,
              },
              petsList
            )
          }
          dispatch(setGlobalLoading(false))
        } catch (error) {
          dispatch(setGlobalLoading(false))
        }
      }
    }
    newLoad()
  }, [dispatch])

  const handleVisitTypeMenuOpen = () => {
    setTimeout(() => {
      if (filterPricingOptions.length === 0) {
        showMessage({
          label: "No Visit Type Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }

  const handleParentListMenuOpen = () => {
    setTimeout(() => {
      if (parentsList.length === 0) {
        showMessage({
          label: "No Parents Available! Need to Create First",
          status: "info",
        })
      }
    }, 1000)
  }
  const handleVisitTypeChange = selectedOption => {
    setReasonType(selectedOption)

    const option = modifyAppointmentType(filterPricingOptions).find(
      item => item.name === getAppointmentType(selectedOption.label)
    )
    setVisitType(option)
    setPricing(option?.pricing || 0)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="appointment-heading-div ">
            <Col
              xs="auto"
              className="d-flex align-items-center justify-content-start gap-2"
            >
              <img
                src={LeftArrow}
                width={32}
                onClick={() => history.goBack()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">{`${
                id ? "Edit" : "New"
              } Appointment`}</h4>
            </Col>

            <Col
              xs="auto"
              className="d-flex align-items-center justify-content-between gap-4"
            >
              {/* <SearchBarComponent /> */}
            </Col>
            {/* <div className="create-link">
              <Link
                color="primary"
                className="font-16 btn-block"
                to={"/add-appointment"}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Appointment
              </Link>
            </div> */}
          </Row>
          {/* <Breadcrumb
            title="Appointments"
            titleLink="/appointments"
            breadcrumbItem={`${id ? "Edit" : "New"} Appointment`}
          /> */}
          <Row>
            <Card>
              {isValidAppointment ? (
                <CardBody>
                  <Form
                    className="form-horizontal row"
                    // onSubmit={e => {
                    //   e.preventDefault()
                    //   handleSubmit
                    //   return false
                    // }}
                  >
                    <Row className="justify-content-end">
                      <Col lg={5}>
                        <FormGroup className="row align-items-center">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Date & Time<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7" onClick={onDateClick}>
                            {/* <Label className="col-form-label text-right text-input-value">
                              {selectedDate
                                ? `${dateFormatAsdddMMYYYY(
                                  selectedDate.day
                                )} - ${selectedDate.time}`
                                : "click to select date"}
                            </Label> */}
                            {selectedDate ? (
                              `${dateFormatAsdddMMYYYY(selectedDate.day)} - ${
                                selectedDate.time
                              }`
                            ) : (
                              <Input
                                type="text"
                                className="form-control"
                                value={"click to select date"}
                                readOnly
                              />
                            )}
                          </div>
                          <DoctorTimings
                            setModal={onDateClick}
                            isOpen={isDateModalOpen}
                            onChangeDate={onChangeDate}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={7}>
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Reason<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              value={ReasonType}
                              onChange={handleVisitTypeChange}
                              options={reasonOptions}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              onMenuOpen={handleVisitTypeMenuOpen}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="justify-content-end mt-8">
                      <Col lg={5}>
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Pet Parent<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              value={selectedParent}
                              onChange={handleSelectedParent}
                              isDisabled={!!id}
                              options={modifyParentType(parentsList)}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                              onMenuOpen={handleParentListMenuOpen}
                            />
                          </div>
                        </FormGroup>
                        {addPet && (
                          <div className="history-doc-cta mb-4 ">
                            <Link
                              className="btn btn-primary"
                              to={`/add-pet/parent/${selectedParent?.value}`}
                            >
                              + Add Pet
                            </Link>
                          </div>
                        )}
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Pet's Name<span className="text-danger">*</span>
                          </Label>
                          <div className="col-lg-7">
                            <ReactSelect
                              value={selectedPet}
                              isDisabled={!allPets.length || isFetchingPets}
                              onChange={handleSelectedPet}
                              options={modifyAppointmentType(allPets)}
                              classNamePrefix="form-react-select"
                              className="form-react-select"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={7}>
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Pet Type
                          </Label>
                          <div className="col-lg-7">
                            {!currentPet?.petType ? (
                              <ReactSelect
                                value={petTypes}
                                isDisabled
                                onChange={handlePetTypeChange}
                                placeholder="Select Pet Type"
                                options={modifyAppointmentType(petType)}
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                              />
                            ) : (
                              <Label className="col-form-label col-lg-12 text-right text-input-label-value text-primary ">
                                {currentPet?.petType}
                              </Label>
                            )}
                          </div>
                        </FormGroup>
                        <FormGroup className="row mb-9">
                          <Label className="col-form-label col-lg-5 text-right text-input-label font-fontFamily-400">
                            Pet's Breed
                          </Label>
                          <div className="col-lg-7">
                            {!currentPet?.petBreed ? (
                              <ReactSelect
                                value={selectedBreed}
                                isDisabled
                                onChange={setSelectedBreed}
                                options={breeds}
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                              />
                            ) : (
                              <Label className="col-form-label col-lg-12 text-right text-input-label-value text-primary">
                                {currentPet?.petBreed}
                              </Label>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2 text-right text-input-label font-fontFamily-400">
                            Notes
                          </Label>
                          <div
                            className="col-lg-10"
                            style={{ paddingLeft: "1.4rem" }}
                          >
                            <textarea
                              className="form-control"
                              id="projectdesc"
                              style={{ resize: "none", minHeight: 200 }}
                              onChange={stateChange("notes")}
                              rows="16"
                              defaultValue={values.notes}
                              placeholder="Enter Notes"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4 justify-content-end" row>
                          <span className="gray-note">
                            To Pay :
                            <span className="font-fontFamily fontSize-24 text-color-primary">
                              &nbsp;INR {pricing}
                            </span>
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <ButtonMain
                        isLoading={isLoading1}
                        className="btn btn-primary btn-med"
                        // type="submit"
                        style={{
                          marginRight: "10px",
                        }}
                        onClick={e => handleSubmitCheckIn(e)}
                      >
                        {"Submit & Check-In "}
                      </ButtonMain>
                      <ButtonMain
                        isLoading={isLoading}
                        className="btn btn-primary btn-med"
                        // type="submit"
                        onClick={e => handleSubmit(e)}
                      >
                        {id ? "Update" : "Submit"}
                      </ButtonMain>
                    </div>
                  </Form>
                </CardBody>
              ) : (
                <CardBody>
                  <CardText>No Appointment found</CardText>
                </CardBody>
              )}
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
