import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Label } from "reactstrap"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Breadcrumb = props => {
  const history = useHistory()
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start gap-2 ">
            <img
              src={LeftArrow}
              width={32}
              onClick={() => {
                history.goBack()
              }}
              role="button"
            />
            <p className="mb-sm-0 font-fontFamily fontSize-28">
              {props.breadcrumbItem}
            </p>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0 ">
              <BreadcrumbItem>
                <div className="font-fontFamily" style={{ cursor: "pointer"}} onClick={() => {
                  history.goBack()
                }}>{props.title}</div>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Label className="font-fontFamily">{props.breadcrumbItem}</Label>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
