import ButtonMain from "components/Common/Button"
import {
  createInventoryItem,
  deleteAisle,
  deleteInventoryType,
  deleteManufacturer,
  getinventoryItemById,
  updateInventoryitem,
} from "helpers/api_helper"
import * as Yup from "yup"
import { useFormik } from "formik"

import React, { useEffect, useMemo, useReducer, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { showMessage } from "components/Notification"

import {
  getAisles,
  getAllAisle,
  getAllInventoryType,
  getAllManufacturers,
  getAllParents,
  getInventoryTypes,
  getManufacturers,
  getpricing,
  getUserDetails,
  setGlobalLoading,
} from "store/slices"
import { useDispatch, useSelector } from "react-redux"
import ReactSelect from "react-select"
import LeftArrow from "../../assets/icons/ArrowLeft.png"
import TableContainer from "components/Common/TableContainer"
import { BillingName, OrderId } from "pages/Parents/components/PatientsCol"
import { IconSVG } from "components/Common/IconSvg"
import DeleteModal from "components/Common/DeleteModal"

const ReceivedItems = () => {
  //meta title
  document.title = "Add inventory"
  // const appointmentType = useSelector(getServices)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})
  const userDetails = useSelector(getUserDetails)
  const manufacturerList = useSelector(getAllManufacturers)
  const aisleList = useSelector(getAllAisle)
  const inventoryTypeList = useSelector(getAllInventoryType)
  const [testType, settestType] = useState()
  const [manufacturerName, setManufacturerName] = useState(null)
  const [aisleName, setAisleName] = useState(null)
  const [inventoryTypeName, setInventoryTypeName] = useState(null)
  const [typeName, setTypeName] = useState(null)

  const { itemId } = useParams()

  const itemTypeOptions = [
    {
      label: "Equipments",
      value: "Equipments",
    },
    {
      label: "Supplies",
      value: "Supplies",
    },
    {
      label: "Drugs",
      value: "Drugs",
    },
    {
      label: "Food Items",
      value: "Food Items",
    },
  ]

  const itemAccuredType = [
    {
      label: "new",
      value: "new",
    },
    {
      label: "received",
      value: "received",
    },
  ]

  const dispatch = useDispatch()
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      itemName: details?.itemName || "",
      itemCode: details?.itemCode || "",
      reOrderLevel: details?.reOrderLevel || "",
      manufacturer: details?.manufacturer || "",
      retailPrice: details?.retailPrice || "",
      stockingUnit: details?.stockingUnit || "",
      tax: details?.tax || "",
      itemType: details?.itemType || "",
      quantity: details?.quantity || "",
      aisleLocation: details?.aisleLocation || "",
      Strength: details?.Strength || "",
      date: details?.date || "",
      invoiceNumber: details?.invoiceNumber || "",
      aisleLocation: details?.aisleLocation || "",
      purchaseCost: details?.purchaseCost || "",
      expirationDate: details?.expirationDate || "",
      otherInfo: details?.otherInfo || "",
      type: details.type || "",
      aisleLocationTitle: details || "",
    },
    validationSchema: Yup.object({
      itemName: Yup.string().required("Please Enter Item Name"),
      itemCode: Yup.string().required("Please Enter Item Code"),
      reOrderLevel: Yup.number()
        .required("Please Enter Re-Order Level")
        .positive(),
      retailPrice: Yup.number()
        .required("Please Enter Retail Price")
        .positive(),
      stockingUnit: Yup.string().required("Please Enter Stocking Unit"),
      tax: Yup.number().required("Please Enter Tax").positive(),
      quantity: Yup.number().required("Please Enter Item Quantity").positive(),
      // Strength: Yup.number().required().positive(),
      // date: Yup.string().required(),
      invoiceNumber: Yup.string()
        .matches(
          /^[a-zA-Z0-9_]+$/,
          "Only alphabets, numbers, and underscores are allowed"
        )
        .required("Please Enter Invoice Number"),
      purchaseCost: Yup.number()
        .required("Please Enter Purchase Cost")
        .positive(),
      // expirationDate: Yup.string().required(),
      // otherInfo: Yup.string().required(),
      // type: Yup.string().required(),
      date: Yup.date().required("Please Enter Receiving Date"),
    }),
    onSubmit: async values => {
      if (manufacturerName === "" || !manufacturerName) {
        showMessage({ label: "Please Select Manufacturer", status: "info" })
        return
      }
      if (aisleName === "" || !aisleName) {
        showMessage({ label: "Please Select Aisle", status: "info" })
        return
      }
      if (typeName === "" || !typeName) {
        showMessage({ label: "Please Select Type", status: "info" })
        return
      }
      if (inventoryTypeName === "" || !inventoryTypeName) {
        showMessage({ label: "Please Select Item Type", status: "info" })
        return
      }
      if (values.date === "") {
        showMessage({ label: "Please Select Recieving Date", status: "info" })
        return
      }
      const obj = {
        vetId: userDetails._id,
        itemName: values.itemName,
        itemCode: values.itemCode,
        reOrderLevel: values.reOrderLevel,
        retailPrice: values.retailPrice,
        stockingUnit: values.stockingUnit,
        tax: values.tax,
        quantity: values.quantity,
        Strength: values.Strength,
        images: [],
        aisleLocationTitle: aisleName.label,
        purchaseInformation: {
          date: values.date,
          invoiceNumber: values.invoiceNumber,
          quantity: values.quantity,
          purchaseCost: values.purchaseCost,
          expirationDate: values.expirationDate,
          otherInfo: values.otherInfo,
          manufacturerDetails: manufacturerName.label,
          invoiceAttachment: "urls",
        },
      }
      // obj.petObjectId = selectedPet.value
      obj.manufacturer = manufacturerName.value
      obj.aisleLocation = aisleName.value
      obj.itemType = inventoryTypeName.label
      obj.type = typeName.label

      if (!manufacturerName) {
        showMessage({ label: "Please Select Manufacture", status: "info" })
        return
      }
      handleSubmit(obj)
      console.log("obj", obj)
    },
  })

  const history = useHistory()
  useEffect(() => {
    async function fetchData() {
      if (!itemId) return
      dispatch(setGlobalLoading(true))
      const _detail = await getinventoryItemById(itemId)
      setDetails({
        itemName: _detail.data.itemName,
        itemCode: _detail.data.itemCode,
        reOrderLevel: _detail.data.reOrderLevel,
        retailPrice: _detail.data.retailPrice,
        stockingUnit: _detail.data.stockingUnit,
        tax: _detail.data.tax,
        quantity: _detail.data.quantity,
        Strength: _detail.data.Strength,
        date: _detail.data.purchaseInformation.date,
        invoiceNumber: _detail.data.purchaseInformation.invoiceNumber,
        purchaseCost: _detail.data.purchaseInformation.purchaseCost,
        expirationDate: _detail.data.purchaseInformation.expirationDate,
        otherInfo: _detail.data.purchaseInformation.otherInfo,
        manufacturer: _detail.data.manufacturer,
        aisleLocation: _detail.data.aisleLocation,
        type: _detail.data.type,
        itemType: _detail.data.itemType,
        // manufacturerDetails: manufacturerName.label,
      })
      dispatch(setGlobalLoading(false))
      console.log("userDetails", _detail)
    }
    fetchData()
  }, [itemId])

  useEffect(() => {
    if (itemId) {
      setManufacturerName({
        value: filterManufacturer?._id,
        label: filterManufacturer?.name,
      })
      setAisleName({
        value: filterAisle?._id,
        label: filterAisle?.name,
      })
      setInventoryTypeName({
        value: details?.itemType,
        label: details?.itemType,
      })
      setTypeName({
        value: details.type,
        label: details.type,
      })
    }
  }, [details])
  const handleSubmit = async values => {
    console.log(values)
    // return
    try {
      setIsLoading(true)
      let res
      if (!itemId) {
        res = await createInventoryItem(values)
      } else {
        res = await updateInventoryitem(itemId, values)
      }

      if (res.status === 1) {
        showMessage({ label: res.message, status: "success" })
        history.push("/inventory")
      } else {
        showMessage({
          label: res.message || "something went wrong",
          status: "error",
        })
      }
      setIsLoading(false)
    } catch (error) {
      showMessage({
        label: error.response.data.message || "something went wrong",
        status: "error",
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    async function newLoad() {
      await dispatch(getManufacturers({ vetId: userDetails._id }))
    }
    newLoad()
  }, [dispatch])

  console.log("manufacturer List", manufacturerList)
  console.log("aisle List", aisleList)
  console.log("inventoryItem List", inventoryTypeList)

  useEffect(() => {
    async function newLoad() {
      await dispatch(getAisles({ vetId: userDetails._id }))
    }

    newLoad()
  }, [dispatch])

  useEffect(() => {
    async function newLoad() {
      await dispatch(getInventoryTypes({ vetId: userDetails._id }))
    }

    newLoad()
  }, [dispatch])

  const modifyInventoryOptions = data => {
    const _data = data.map(item => {
      return {
        value: item._id,
        label: item.name ? item.name : item.title,
      }
    })
    return _data
  }

  console.log("details", details)
  const filterManufacturer = manufacturerList.find(
    ele => ele._id === details?.manufacturer
  )
  console.log("filtermanufacturer", filterManufacturer)

  const filterAisle = aisleList.find(ele => ele._id === details?.aisleLocation)
  console.log("filterAisle", filterAisle)

  const filterInventoryType = inventoryTypeList.find(
    ele => ele._id === details?.itemType
  )
  console.log("filterInventoryType", filterInventoryType)

  const handleManufacturerMenuOpen = () => {
    setTimeout(() => {
      if (manufacturerList.length === 0) {
        showMessage({
          label: "No Manufacturer Available! Need to Create First",
          status: "info",
        })
        setTimeout(() => {
          handleAddNewManufacturer()
        }, 1000) //
      }
    }, 1000)
  }

  const handleAisleMenuOpen = () => {
    setTimeout(() => {
      if (aisleList.length === 0) {
        showMessage({
          label: "No Aisle Available! Need to Create First",
          status: "info",
        })
        setTimeout(() => {
          handleAddNewAisle()
        }, 1000) //
      }
    }, 1000)
  }
  const handleItemTypeMenuOpen = () => {
    setTimeout(() => {
      if (inventoryTypeList.length === 0) {
        showMessage({
          label: "No Item Type Available! Need to Create First",
          status: "info",
        })
        setTimeout(() => {
          handleOrderAddCategory()
        }, 1000) //
      }
    }, 1000)
  }

  //Aisle Location Code
  const Aisles = useSelector(getAllAisle)
  const [selectedAisle, setSelectedAisle] = useState(null)
  const handleAddNewAisle = () => {
    history.push("/add-aisle-details")
  }
  const handleDeleteAisle = async () => {
    // if (order._id) {
    const res = await deleteAisle(selectedAisle._id)
    if (res.status == 1) {
      dispatch(getAisles({ vetId: userDetails._id }))
    }
    setDeleteAisleModal(false)
  }
  const [deleteAisleModal, setDeleteAisleModal] = useState(false)
  const onClickDeleteAisle = aisle => {
    setSelectedAisle(aisle)
    setDeleteAisleModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },

      {
        Header: "Address",
        accessor: "address",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Notes",
        accessor: "otherDetails",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
          "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(
                    `/edit-aisle-details/${cellProps.row.original._id}`
                  )
                }}
              >
                <i id={"edittooltip" + cellProps.row.original._id}>
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip
                  placement="top"
                  target={"edittooltip" + cellProps.row.original._id}
                >
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDeleteAisle(orderData)
                }}
              >
                <i id={"deletetooltip" + cellProps.row.original._id}>
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip
                  placement="top"
                  target={"deletetooltip" + cellProps.row.original._id}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  //Category Types Code
  const inventoryTypes = useSelector(getAllInventoryType)
  const [selectedType, setSelectedType] = useState(null)
  const handleOrderAddCategory = () => {
    history.push("/add-inventoryType")
  }
  const handleDeleteOrderCategory = async () => {
    // if (order._id) {
    const res = await deleteInventoryType(selectedType._id)
    if (res.status == 1) {
      dispatch(getInventoryTypes({ vetId: userDetails._id }))
    }
    setDeleteCategoryModal(false)
  }
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false)
  const onClickDeleteCategory = aisle => {
    setSelectedType(aisle)
    setDeleteCategoryModal(true)
  }
  const columns2 = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Attribute",
        accessor: "attributes",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(
                    `/edit-inventoryType/${cellProps.row.original._id}`
                  )
                }}
              >
                <i id={"edittooltip" + cellProps.row.original._id}>
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip
                  placement="top"
                  target={"edittooltip" + cellProps.row.original._id}
                >
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDeleteCategory(orderData)
                }}
              >
                <i id={"deletetooltip" + cellProps.row.original._id}>
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip
                  placement="top"
                  target={"deletetooltip" + cellProps.row.original._id}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  //Supplier Code
  const manufacturers = useSelector(getAllManufacturers)
  const [selectedManufacturer, setSelectedManufacturer] = useState(null)
  const handleAddNewManufacturer = () => {
    history.push("/add-manufacturers")
  }
  const onClickDeleteManufacturer = manufacturer => {
    setSelectedManufacturer(manufacturer)
    setDeleteManufacturerModal(true)
  }
  const [deleteManufacturerModal, setDeleteManufacturerModal] = useState(false)
  const handleDeleteOrderManufacturer = async () => {
    // if (order._id) {
    const res = await deleteManufacturer(selectedManufacturer._id)
    if (res.status == 1) {
      dispatch(getManufacturers({ vetId: userDetails?._id }))
      // location.reload()
    }
    setDeleteManufacturerModal(false)
  }
  const columns3 = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#fff",
        },
        filterable: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Notes",
        accessor: "otherDetails",
        filterable: true,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        className: "text-right",
        style: {
          textAlign: "right",
          background: "#0000",
        },
        Cell: cellProps => {
          return (
            <div
              className="d-flex gap-3 justify-content-end
            "
            >
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  history.push(
                    `/edit-manufacturers/${cellProps.row.original._id}`
                  )
                }}
              >
                <i id={"edittooltip" + cellProps.row.original._id}>
                  <IconSVG icon="edit" />
                </i>
                <UncontrolledTooltip
                  placement="top"
                  target={"edittooltip" + cellProps.row.original._id}
                >
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDeleteManufacturer(orderData)
                }}
              >
                <i id={"deletetooltip" + cellProps.row.original._id}>
                  <IconSVG icon="delete" />
                </i>

                <UncontrolledTooltip
                  placement="top"
                  target={"deletetooltip" + cellProps.row.original._id}
                >
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const [activeTab, setActiveTab] = useState(0)
  const [activeTabName, setActiveTabName] = useState()

  useEffect(() => {
    setActiveTabName(inventoryDetails[0])
  }, [])
  console.log("ActiveTabName", activeTabName)
  const handleTabClick = tabId => {
    setActiveTab(tabId)
    setActiveTabName(inventoryDetails[tabId])
  }
  //xpecto Developer 6/7/2024
  const inventoryDetails = [
    "Suppliers",
    "Item Category",
    "Aisles",
    "Item Details",
  ]
  //xpecto Developer 6/7/2024

  const BackButton = () => {
    window.history.back()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mx-2 ">
            <Col className="d-flex align-items-center justify-content-start gap-2">
              <img
                src={LeftArrow}
                width={32}
                onClick={() => BackButton()}
                role="button"
              />
              <h4 className="mb-0 font-fontFamily fontSize-28">
                Inventory Item Recieved
              </h4>
            </Col>

            <Col className="d-flex align-items-center justify-content-between gap-4"></Col>
          </Row>
          <Row className="mt-4 px-4">
            <Nav tabs>
              {inventoryDetails.map((pVal, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={activeTab === index ? "active" : ""}
                    onClick={() => handleTabClick(index)}
                    style={{
                      color: activeTab === index ? "#652B88" : "#6B7280",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      borderBottom:
                        activeTab === index ? "2px solid #652B88" : "",
                      fontSize: "18px",
                      paddingBottom: "7px",
                    }}
                  >
                    {pVal}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Row>
          <DeleteModal
            show={deleteAisleModal}
            onDeleteClick={handleDeleteAisle}
            onCloseClick={() => setDeleteAisleModal(false)}
          />
          <DeleteModal
            show={deleteCategoryModal}
            onDeleteClick={handleDeleteOrderCategory}
            onCloseClick={() => setDeleteCategoryModal(false)}
          />
          <DeleteModal
            show={deleteManufacturerModal}
            onDeleteClick={handleDeleteOrderManufacturer}
            onCloseClick={() => setDeleteManufacturerModal(false)}
          />
          {activeTabName === "Suppliers" ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns3}
                      data={manufacturers}
                      isGlobalFilter={false}
                      isAddOptions={true}
                      handleOrderClicks={handleAddNewManufacturer}
                      emptyListLink="/add-manufacturers"
                      emptyText="No Suppliers found"
                      emptyLinkText="+ Add New Supplier"
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : activeTabName === "Aisles" ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={Aisles}
                      isGlobalFilter={false}
                      isAddOptions={true}
                      handleOrderClicks={handleAddNewAisle}
                      emptyListLink="/add-aisle-details"
                      emptyText="No Aisle found"
                      emptyLinkText="+ Add New Aisle"
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : activeTabName === "Item Category" ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns2}
                      data={inventoryTypes}
                      isGlobalFilter={false}
                      isAddOptions={true}
                      handleOrderClicks={handleOrderAddCategory}
                      emptyListLink="/add-inventoryType"
                      emptyText="No Category Found"
                      emptyLinkText="+ Add New Category"
                      customPageSize={10}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row></Row>
                      <div className="appointment-heading-div">
                        <h4 className=" fontSize-24 font-fontFamily margin-top-36 margin-bottom-39">
                          Item Details
                        </h4>
                        <div className="create-link"></div>
                      </div>

                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="itemName"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Inventory Item
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="itemName"
                                name="itemName"
                                type="text"
                                value={validation.values.itemName || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Inventory Item"
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.itemName &&
                                  validation.errors.itemName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.itemName &&
                              validation.errors.itemName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.itemName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="itemCode"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Item Code<span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="itemCode"
                                name="itemCode"
                                type="text"
                                value={validation.values.itemCode || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Item Code"
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.itemCode &&
                                  validation.errors.itemCode
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.itemCode &&
                              validation.errors.itemCode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.itemCode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="reOrderLevel"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Re-Order Level
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="reOrderLevel"
                                name="reOrderLevel"
                                type="number"
                                value={validation.values.reOrderLevel || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Re-Order Level"
                                onBlur={validation.handleBlur}
                                min="0"
                                invalid={
                                  validation.touched.reOrderLevel &&
                                  validation.errors.reOrderLevel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.reOrderLevel &&
                              validation.errors.reOrderLevel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reOrderLevel}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="manufacturer"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Supplier<span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <ReactSelect
                                name="manufacuturer"
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                options={modifyInventoryOptions(
                                  manufacturerList
                                )}
                                value={manufacturerName}
                                onChange={setManufacturerName}
                                onMenuOpen={handleManufacturerMenuOpen}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {/* <Col md={2}>
                          <ButtonMain
                            // isLoading={isLoading}
                            type="button"
                            className="btn btn-med btn-primary"
                            onClick={() => history.push("/add-manufacturers")}
                          >
                            + Add Supplier
                          </ButtonMain>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="retailPrice"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Retail Price<span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="retailPrice"
                                name="retailPrice"
                                type="number"
                                value={validation.values.retailPrice || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Retail Price"
                                onBlur={validation.handleBlur}
                                min="0"
                                invalid={
                                  validation.touched.retailPrice &&
                                  validation.errors.retailPrice
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.retailPrice &&
                              validation.errors.retailPrice ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.retailPrice}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="stockingUnit"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Stocking Unit
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="stockingUnit"
                                name="stockingUnit"
                                type="text"
                                value={validation.values.stockingUnit || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Stocking unit"
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.stockingUnit &&
                                  validation.errors.stockingUnit
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.stockingUnit &&
                              validation.errors.stockingUnit ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.stockingUnit}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="tax"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Tax<span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="tax"
                                name="tax"
                                type="number"
                                value={validation.values.tax || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Tax"
                                onBlur={validation.handleBlur}
                                min="0"
                                invalid={
                                  validation.touched.tax &&
                                  validation.errors.tax
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tax &&
                              validation.errors.tax ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tax}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="itemType"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Item Category
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <ReactSelect
                                name="itemType"
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                options={modifyInventoryOptions(
                                  inventoryTypeList
                                )}
                                value={inventoryTypeName}
                                onChange={setInventoryTypeName}
                                onMenuOpen={handleItemTypeMenuOpen}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {/* <Col>
                          <ButtonMain
                            // isLoading={isLoading}
                            type="button"
                            className="btn btn-med btn-primary"
                            onClick={() => history.push("/add-inventoryType")}
                          >
                            + Add Type
                          </ButtonMain>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="quantity"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Quantity<span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="quantity"
                                name="quantity"
                                type="number"
                                value={validation.values.quantity || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Quantity"
                                onBlur={validation.handleBlur}
                                min="0"
                                invalid={
                                  validation.touched.quantity &&
                                  validation.errors.quantity
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.quantity &&
                              validation.errors.quantity ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.quantity}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="aisleLocation"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Aisle Location
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <ReactSelect
                                name="aisleLocation"
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                options={modifyInventoryOptions(aisleList)}
                                value={aisleName}
                                onChange={setAisleName}
                                onMenuOpen={handleAisleMenuOpen}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {/* <Col md={2}>
                          <ButtonMain
                            // isLoading={isLoading}
                            type="button"
                            className="btn btn-med btn-primary"
                            onClick={() => history.push("/add-aisle-details")}
                          >
                            + Add Aisle
                          </ButtonMain>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="quantity"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Item Strength
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="Strength"
                                name="Strength"
                                type="number"
                                value={validation.values.Strength || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="mg"
                                onBlur={validation.handleBlur}
                                min="0"
                                invalid={
                                  validation.touched.Strength &&
                                  validation.errors.Strength
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Strength &&
                              validation.errors.Strength ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Strength}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          {" "}
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="type"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Type<span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <ReactSelect
                                name="type"
                                classNamePrefix="form-react-select"
                                className="form-react-select"
                                options={itemAccuredType}
                                value={typeName}
                                onChange={setTypeName}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="appointment-heading-div">
                        <h4 className=" fontSize-24 font-fontFamily margin-top-45 margin-bottom-39">
                          Purchase Details
                        </h4>

                        <div className="create-link"></div>
                      </div>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="date"
                              className="ol-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Date Recieved
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="date"
                                name="date"
                                type="date"
                                value={validation.values.date || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Item Quantity"
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.date &&
                                  validation.errors.date
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.date &&
                              validation.errors.date ? (
                                <FormFeedback date="invalid">
                                  {validation.errors.date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="expirationDate"
                              className="ol-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Expiration Date
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="expirationDate"
                                name="expirationDate"
                                type="date"
                                value={validation.values.expirationDate || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Item Quantity"
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.expirationDate &&
                                  validation.errors.expirationDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.expirationDate &&
                              validation.errors.expirationDate ? (
                                <FormFeedback expirationDate="invalid">
                                  {validation.errors.expirationDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="pl-0">
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="invoiceNumber"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Invoice Number
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="invoiceNumber"
                                name="invoiceNumber"
                                type="text"
                                value={validation.values.invoiceNumber || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Invoice Number"
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.invoiceNumber &&
                                  validation.errors.invoiceNumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.invoiceNumber &&
                              validation.errors.invoiceNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.invoiceNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3" row>
                            <Label
                              htmlFor="purchaseCost"
                              className="col-form-label col-lg-4 text-right text-input-label font-fontFamily-400"
                            >
                              Purchase Cost
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="col-lg-8">
                              <Input
                                id="purchaseCost"
                                name="purchaseCost"
                                type="number"
                                value={validation.values.purchaseCost || ""}
                                onChange={validation.handleChange}
                                className="form-control"
                                placeholder="Enter Item's Cost"
                                onBlur={validation.handleBlur}
                                min="0"
                                invalid={
                                  validation.touched.purchaseCost &&
                                  validation.errors.purchaseCost
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.purchaseCost &&
                              validation.errors.purchaseCost ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.purchaseCost}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} className="pl-0">
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="otherInfo"
                              className="col-form-label col-lg-2 text-right text-input-label font-fontFamily-400"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Other Info
                            </Label>
                            <div className="col-lg-8">
                              <textarea
                                className="form-control"
                                id="otherInfo"
                                rows="8"
                                name="otherInfo"
                                style={{ resize: "none", minHeight: 200 }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.otherInfo || ""}
                                placeholder="Other Info"
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={2}></Col>
                      </Row>

                      <Row className="justify-content-end mt-8 ms-15 gap-2">
                        <ButtonMain
                          // isLoading={isLoading}
                          onClick={BackButton}
                          type="button"
                          className="btn btn-med btn-primary me-3"
                        >
                          Cancel
                        </ButtonMain>

                        <ButtonMain
                          // isLoading={isLoading}
                          type="submit"
                          className="btn btn-med btn-primary"
                        >
                          {itemId ? "Update" : "Add"}
                        </ButtonMain>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReceivedItems
